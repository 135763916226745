import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'statusDp'
})
export class StatusDpPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      if (item.status != null) {
        return item.status.toLowerCase() === searchText;
      }
    });
  }
}