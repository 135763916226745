import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'adminServicesF'
})
export class AdminServicesPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    return items.filter(item => {
      if(item.service_name != null){
        return item.service_name.toLowerCase().includes(searchText);
      }
    });
  }
}