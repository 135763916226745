import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared-service.service';
import { CommonService } from '../../../shared/services/commonService.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../../services/loaderService';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  searchReference: FormControl;
  searchText: FormControl;
  newValue: string;
  searchResult: any;
  searchResultArray: Array<any>;
  /**
   * Form
   */
  reactiveForm: FormGroup;
  countriesReactive: Array<any>=[];

  public placeholder: string = 'Global Search';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private commonService: CommonService,
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
  ) {
    this.searchReference = new FormControl(`${this.sharedService.searchText}`, Validators.required);
  }

  ngOnInit() {
    this.reactiveForm = this.formBuilder.group({
      name: [{value: '', disabled: false}, Validators.required]
    });
    if (this.sharedService.searchText == undefined || this.sharedService.searchText == '') {
      this.router.navigate(['user/home']);
    }
    else {
      this.searchReference = new FormControl(`${this.sharedService.searchText}`, Validators.required);
    }
    this.searchServices();
    this.searchServicesAutoList()
  }

  searchServices() {
    this.loaderService.display(true);
    this.commonService.getGlobalReferenceSearchResults('all',this.searchReference.value).subscribe(data => {
      this.searchResult = data;
      if(this.searchResult['Success'] > 0){
        this.searchResultArray = this.searchResult['Message'];
      }
      else{
        this.toasterService.error(this.searchResult['Message']);
      }
      this.loaderService.display(false);
    },
      error => {
        this.loaderService.display(false);
        this.toasterService.error(error);
      })
  }

  searchServicesAutoList() {
    this.loaderService.display(true);
    this.commonService.getGlobalReferenceSearchResults('all','').subscribe(data => {
      this.searchResult = data;
      if(this.searchResult['Success'] > 0){
        this.searchResult['Message'].forEach((value) => {
          
          if(value.index_name === "business_glossaries"){
            this.countriesReactive.push(value.data['term_name']);
          }else if(value.index_name=="reference_master"){
            this.countriesReactive.push(value.data['Reference_Name']);
          }else if(value.index_name=="data_dictionary"){
            this.countriesReactive.push(value.data['table_name']);
          }else{
            this.countriesReactive.push(value.data['Table_Name']);
          }

        })
      }
      else{
        this.toasterService.error(this.searchResult['Message']);
      }
      this.loaderService.display(false);
    },
      error => {
        this.loaderService.display(false);
        this.toasterService.error(error);
      })
  }

  getServiceDetails(id: any, indexName: string) {
    if(indexName == 'reference_master'){
      this.router.navigate(['user/searchResults/details/', id]);
    }
    else if(indexName == 'business_glossaries'){
      this.router.navigate(['user/business/details/', id]);
    }
    else if(indexName == 'data_dictionary'){
      this.router.navigate(['user/dataDictionary/details/', id]);
    }
  }

  getDataQualityServiceDetails(id: any, indexName: string, date){
    if(indexName == 'data_quality'){
      this.sharedService.setCollectionDate(date);
      this.router.navigate(['user/dataQuality/details/', id]);
    }
  }
}
