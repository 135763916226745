import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Params } from "@angular/router";
import { CommonService } from "../../../shared/services/commonService.service";
import { LoaderService } from "../../../services/loaderService";
import { ToastrService } from "ngx-toastr";
import { HttpEventType, HttpResponse, HttpEvent } from "@angular/common/http";
import { FileHandle } from "../../../shared/directives/drag-drop.directive";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
// import { CSVUploadModalContent } from '../../modal/csv-upload-modal.component';
// import { CsvUploadSuccessComponent } from '../../modal/csv-upload-success/csv-upload-success.component';

@Component({
  selector: "app-ref-result-details",
  templateUrl: "./ref-result-details.component.html",
  styleUrls: ["./ref-result-details.component.scss"],
})
export class RefResultDetailsComponent implements OnInit {
  @ViewChild("uploadStatus", { static: true }) public uploadStatus: TemplateRef<any>;
  @BlockUI() blockUI: NgBlockUI;
  referenceName: string;
  searchReferenceInput = new FormControl;
  resultDetails: any;
  uploadSectionData: any;
  displayedColumns: string[] = [];
  contentResult: Array<any> = [];
  UI_File_Name: any;
  totalItems: number;
  fileToUpload: any;
  fileName: any;
  fileUploadDone: boolean = false;
  search: boolean = false;
  updateMode: boolean = true;
  successUploadData: any;
  summaryFile: string;
  refStatus:string;
  elasticId:string;
  percentDone: number;
  currentPage: number;
  itemsPerPage: number;
  ownerName:any;
  ownerMatched: boolean = false;
  historyDate: Array<any>;
  selectedDataElement: any;
  historyDateLenght:any;
  historyFileSequence:any;
  newRefAdded:boolean=false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public loaderService: LoaderService,
    private toasterService: ToastrService,
    private commonService: CommonService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private _location: Location
  ) { }

  ngOnInit() {
    //this.historyDate=[{"history_date": "2021-01-28T08:08:37", "history_file_sequence": 1 },{"history_date": "2021-01-28T08:18:17","history_file_sequence": 2}];
    this.searchReferenceInput = new FormControl('');
    this.percentDone = 0;
    this.currentPage = 1;
    this.totalItems = 0;
    this.itemsPerPage = 50;
    this.summaryFile = '';
    this.refStatus=this.activatedRoute.snapshot.paramMap.get("disabled")
    this.elasticId=this.activatedRoute.snapshot.paramMap.get("eId")

    this.activatedRoute.params.subscribe((params: Params) => {
      this.referenceName = params["id"];
      this.getRefResultDetails();
    });
    
  }

  searchReference() {
    if (this.searchReferenceInput.value == '') {
      this.search = false;
      this.getRefResultDetails();
    }
    else {
      this.loaderService.display(true);
      this.search = true;
      this.commonService.getReferenceDetailSearchResults(this.searchReferenceInput.value, this.referenceName, this.currentPage, this.itemsPerPage).subscribe(result => {

        if (result['Success'] > 0) {
          this.contentResult = result['Message'].data;
          
          this.totalItems = result['Message'].total_count;
        }
        else {
          this.toasterService.error(result['Message']);
        }


        this.loaderService.display(false);
      },
        error => {
          this.loaderService.display(false);
          this.toasterService.error(error);
        })
    }

  }

  getRefResultDetails() {
    this.loaderService.display(true);
    this.commonService.getReferenceResultDetails(this.referenceName, this.currentPage, this.itemsPerPage,this.historyFileSequence).subscribe(
      (data) => {

        if (data["Success"] > 0) {
          if(data['Message']!="New File"){
          this.historyDate=data['Message']['History_Date']
          this.historyDateLenght=this.historyDate.length
          this.displayedColumns = data['Message']['header'];
          this.contentResult = data['Message']['data'];
          this.UI_File_Name = data['Message'].UI_File_Name;
          this.totalItems = data['Message']['total_count'];
          this.newRefAdded=false;
        }else{
          this.newRefAdded=true;
        }
          
          
        } else {
          this.toasterService.error(data['Message']);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        this.toasterService.error('Something went wrong!')
      }
    );
  }

  updateServices(uploadCSV) {
    this.loaderService.display(true);
    this.commonService.getRefResultUploadDetails(this.referenceName).subscribe(
      (data) => {

        if (data["Success"] > 0) {
          this.uploadSectionData = data["Message"];
          this.ownerName=this.uploadSectionData.Owner;
          if (this.uploadSectionData.Approver!=null) {
          this.ownerMatched=this.uploadSectionData.Approver.includes(JSON.parse(sessionStorage.getItem("user")).UserName);
          }
          //this.ownerMatched=(this.uploadSectionData.Owner==JSON.parse(sessionStorage.getItem("user")).UserName);
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );

    setTimeout(() => {
      this.modalService.open(uploadCSV, { centered: true });
    }, 1000);
  }

  handleFileUpload(e) {
    this.fileToUpload = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (this.commonService.validateFile(this.fileToUpload)) {
      if(!this.newRefAdded){
      this.upload(this.fileToUpload);
      }else{
        this.uploadNewFile(this.fileToUpload);
      }
    }

    e.target.value = "";
  }

  filesDropped(files: FileHandle[]): void {

    if (files.length > 1) {
      this.toasterService.error("Only one file is allowed");
    } else {
      if (this.commonService.validateFile(files[0].file)) {
        this.upload(files[0].file);
      }
    }
  }

  upload(file) {
    this.fileUploadDone = true;
    let formData: FormData = new FormData();

    
    formData.append("file", file);

    this.commonService.uploadCSV(formData).subscribe(
      (event) => {

        if (event.type === HttpEventType.Response) {
          if (event.body['Error'] > 0) {
            this.toasterService.error(event.body['Message']);
            this.percentDone = 0;
            this.fileUploadDone = false;
            return;
          }
          else {
            if (event.body['Error'] > 0) {
              this.toasterService.error(event.body['Message']);
              this.percentDone = 0;
              return;
            } else {
              this.successUploadData = event.body['Message'];
              this.toasterService.success('File uploaded succesfully')
              this.modalService.dismissAll();
              setTimeout(() => {
                this.uploadSuccess();
              }, 500);
            }

          }
        }
        else {
          if (event.type === HttpEventType.UploadProgress) {

            // This is an upload progress event. Compute and show the % done:
            this.percentDone = Math.round((100 * event.loaded) / event.total);
            if (this.percentDone == 100) {
              this.fileUploadDone = false;
            }
          }
        }
      },

      (error) => {
        this.toasterService.error(event["Message"]);
        this.fileUploadDone = false;
      }
    );
  }

  uploadNewFile(file) {
    this.fileUploadDone = true;
    let formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("username", JSON.parse(sessionStorage.getItem("user")).UserName);
    formData.append("_id", this.elasticId);
    this.commonService.uploadNewRefFile(formData,this.elasticId).subscribe(
      (event) => {

        if (event.type === HttpEventType.Response) {
          if (event.body['Error'] > 0) {
            this.toasterService.error(event.body['Message']);
            this.percentDone = 0;
            this.fileUploadDone = false;
            return;
          }
          else {
            if (event.body['Error'] > 0) {
              this.toasterService.error(event.body['Message']);
              this.percentDone = 0;
              return;
            } else {
              this.successUploadData = event.body['Message'];
              this.toasterService.success('File uploaded succesfully')
              this.modalService.dismissAll();
              setTimeout(() => {
                //this.uploadSuccess();
                this.getRefResultDetails();
              }, 500);
            }

          }
        }
        else {
          if (event.type === HttpEventType.UploadProgress) {

            // This is an upload progress event. Compute and show the % done:
            this.percentDone = Math.round((100 * event.loaded) / event.total);
            if (this.percentDone == 100) {
              this.fileUploadDone = false;
            }
          }
        }
      },

      (error) => {
        this.toasterService.error(event["Message"]);
        this.fileUploadDone = false;
      }
    );
  }

  downloadTemplateFile() {
    this.loaderService.display(true);

    this.commonService
      .downloadTemplate(this.uploadSectionData["File_Name"])
      .subscribe(
        (data) => {

          let url = window.URL.createObjectURL(data);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = this.uploadSectionData["UI_File_Name"];
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();

          this.loaderService.display(false);
          this.toasterService.success("File downloaded");
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
          this.loaderService.display(true);
        }
      );
  }

  uploadSuccess() {
    this.modalService.open(this.uploadStatus, { centered: true });
  }

  viewUpdatedRef(e) {
    
    this.summaryFile = e;
    this.loaderService.display(true);
    this.commonService.getComparision(e, this.currentPage, this.itemsPerPage).subscribe(data => {
      // this.resultDetails = data;
      if (data["Success"] > 0) {
        this.displayedColumns = data['Message']['header']; 

        // find index of Stauts column
        var indexOfStatusCol = this.displayedColumns.indexOf('Status');

        // remove it from Table headers array
        this.displayedColumns.splice(indexOfStatusCol, 1);

        // Add back Status column at first index
        this.displayedColumns.unshift('Status');

        this.contentResult = data['Message']['data'];
        // this.contentResult = this.contentResult.slice(0, 50);
        this.totalItems = data['Message']['total_count'];
        this.updateMode = false;
        this.modalService.dismissAll();

      } else {
        this.toasterService.error(data['Message']);
      }
      this.loaderService.display(false);
    },
      (error) => {
        this.loaderService.display(false);
      })
  }

  pageChanged(event: any, updateMode, search): void {

    this.currentPage = event;
    this.loaderService.display(true);
    
    if (search) {
      this.loaderService.display(true);
      this.search = true;
      this.commonService.getReferenceDetailSearchResults(this.searchReferenceInput.value, this.referenceName, this.currentPage, this.itemsPerPage).subscribe(result => {

        if (result['Success'] > 0) {
          this.contentResult = result['Message'].data;

          this.totalItems = result['Message'].total_count;
        }
        else {
          this.toasterService.error(result['Message']);
        }


        this.loaderService.display(false);
      },
        error => {
          this.loaderService.display(false);
          this.toasterService.error(error);
        })
    }
    else {
      if (updateMode) {
        this.commonService
          .getReferenceResultDetails(this.referenceName, this.currentPage, this.itemsPerPage,this.historyFileSequence)
          .subscribe(
            (data) => {

              if (data["Success"] > 0) {
                this.displayedColumns = data['Message']['header'];
                this.contentResult = data['Message']['data'];
                // this.contentResult = this.contentResult.slice(0, 50);
                this.totalItems = data['Message']['total_count']
              } else {
                this.toasterService.error(data['Message']);
              }
              this.loaderService.display(false);
            },
            (error) => {
              this.loaderService.display(false);
            }
          );
      }
      else {
        this.currentPage = event;
        // get list again 
        this.commonService.getComparision(this.summaryFile, this.currentPage, this.itemsPerPage).subscribe(data => {
          // this.resultDetails = data;
          if (data["Success"] > 0) {

            this.contentResult = data['Message']['data'];
            this.totalItems = data['Message']['total_count'];

          } else {
            this.toasterService.error(data['Message']);
          }
          this.loaderService.display(false);
        },
          (error) => {
            this.loaderService.display(false);
          })
      }
    }
  }

  sendForApprovalBtn() {
    this.commonService.sendForApprovalApi(this.successUploadData.uploaded_file, this.successUploadData.summary_file).subscribe(data => {
      if (data["Success"] > 0) {
        this.toasterService.success(data['Message']);
        this.getRefResultDetails();
        this.updateMode = true;
      } else {
        this.toasterService.error(data['Message']);
      }
    },
      error => {
        this.toasterService.error('Something went wrong!');
      })
  }

  acceptUpdate() {
    this.blockUI.start('Updating List...Do not press Refresh or Go back !!');
    this.commonService.approveChanges(this.successUploadData.uploaded_file, this.successUploadData.summary_file).subscribe(data => {
      if (data["Success"] > 0) {
        this.toasterService.success(data['Message']);
        this.blockUI.stop();
        this.getRefResultDetails();
        this.updateMode = true;
      } else {
        this.blockUI.stop();
        this.toasterService.error(data['Message']);
      }
    },
      error => {
        this.blockUI.stop();
        this.toasterService.error('Something went wrong!');
      })
  }

  declineUpdate() {
    this.commonService.declineChanges(this.successUploadData.uploaded_file, this.successUploadData.summary_file).subscribe(data => {
      if (data["Success"] > 0) {
        this.toasterService.success(data['Message']);
        this.getRefResultDetails();
        this.updateMode = true;
      } else {
        this.toasterService.error(data['Message']);
      }
    },
      error => {
        this.toasterService.error('Something went wrong!');
      })
  }

  downloadServices() {

    this.blockUI.start('Download in progress...Kindly wait till download completes')
    this.commonService
      .downloadServices(this.referenceName)
      .subscribe(
        (data) => {

          let url = window.URL.createObjectURL(data);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = this.UI_File_Name;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();

          this.blockUI.stop();
          this.toasterService.success("File downloaded");
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  redirectBack() {
    this._location.back();
  }

  getValues(e) {
    this.historyFileSequence=this.selectedDataElement;
    //this.referenceName = params["id"];
      this.getRefResultDetails();
  }
}
