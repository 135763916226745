import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'dqMetric'
})
export class DQMetricPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      if (item.dq_metric != null) {
        return item.dq_metric.toLowerCase().includes(searchText);
      }
    });
  }
}