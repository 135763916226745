import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { LoaderService } from "src/app/services/loaderService";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/shared/services/admin.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';


@Component({
  selector: "app-admin-page",
  templateUrl: "./admin-page.component.html",
  styleUrls: ["./admin-page.component.scss"],
})
export class AdminPageComponent implements OnInit {
  @ViewChild("addUser", { static: true }) public addUser: TemplateRef<any>;
  @ViewChild(SelectAutocompleteComponent, { static: false }) multiSelect: SelectAutocompleteComponent;
  @ViewChild("addReference", { static: true }) public addReference: TemplateRef<any>;
  user: any;
  item: any = 0;
  item1:any=0;
  itemServices:any=0;
  //referenceItem: any = 0;
  selectedItem: any;
  updateDetailsForm: FormGroup;
  addUserForm: FormGroup;
  editRefForm:FormGroup
  addReferenceForm: FormGroup;
  submitted = false;
  submittedAddModal = false;
  
  submittedReferenceManagement = false;
  submittedAddRef = false;
  searchTerm: FormControl;
  userImageURL: any = null;
  userId: string;
  user_name: string;
  userRole: string;
  profileRole: string;
  sectionRole:string;
  delegationUserInput: string;
  servicesInput: string;
  referenceInput: string;
  selectedUser: boolean;
  selectedUserIndex: string;
  userObjet: any;
  servicesObj:any;
  ownerName:any;
  refId:string;
  ownerList: Array<any> = [];

  allUserMessage: Array<any>;
  refManagementMessage:Array<any>;
  refFileExtensionList:Array<any>;
  refManagementOwnerList:Array<any>;
  refFileList
  servicesMessage: Array<any>;
  servicesAllMessage: Array<any>;
  servicesRefMessage=[];//: Array<any>;
  servicesUiMessage=[];

  fileName = '';
  fileUploadDone=false;

  loader: boolean = false;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedAddItems=[];
  regExp = /^[a-zA-Z]*$/;

  radioItems: Array<string>;
  model   = {option: 'All Services'};
  userSearch: FormControl;

  //ng select
  ownerAddRefSelect:any;
  ownerEditRefSelect:any;
  fileExtAddRefSelect:any;

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    public modalService: NgbModal,
    private adminService: AdminService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    
    this.radioItems = ['All Services', 'Reference Services', 'Backend'];
    this.refFileExtensionList=['.txt', '.csv', '.xlsx','.xlx'];
    this.user = JSON.parse(sessionStorage.getItem('user'));
    // get at of list viwe for refmagmnt
    this.getAllReferenceList();
    this.delegationUserInput = '';
    this.referenceInput='';
    this.servicesInput='';
    
    this.selectedUser = false;
    this.allUserMessage = [];

    //API call for All users list
    this.getAllAdminUsersList();
    this.searchTerm = new FormControl('');
    //this.getAllServicesList();

    this.updateDetailsForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.maxLength(50)]],
      last_name: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
      role: [this.userId, Validators.required],
      disabled:[''],
      profile:['', Validators.required],
      section:['', Validators.required],
    });

    this.addUserForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.maxLength(50)]],
      last_name: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
      role: ["User", Validators.required],
      profile:['ReadOnly', Validators.required],
      section:['Dashboard', Validators.required],
    });

    this.userSearch = new FormControl('');

    this.addReferenceForm=this.formBuilder.group({

      Reference_Name: ['', [Validators.required, Validators.maxLength(50)]],
      Reference_Technical_Name: ['', [Validators.required, Validators.maxLength(50)]],
      Delimiter:['',[Validators.maxLength(1)]],
      File_Header: ['',[]],
      Need_Header: ['', []],
      Reference_Hivetable_Name:['', [Validators.maxLength(100)]],
      Date_Position:['',Validators.required],
      Owner: [this.ownerName, Validators.required],
      Approver: [this.selectedAddItems, Validators.required],//Collect_AI_Pset_Dev
      Category_Group:['',[]],
      Disabled: ['',[]],
      enable_job:['',[]],
      Landing_Dir_Prod:['',[]],
      Collect_AI_Pset_Prod:['',[]],
      Collect_Execution_Commands_Dev:['',[]],
      Collect_Server_Prod:['',[]],
      Hive_AI_Pset_Prod:['',[]],
      Hive_Execution_commands_Dev:['',[]],
      Edge_Server_Hive_Prod:['',[]],
      Dependency:['',[]],
      Reference_Name_Depend:['',[]],
        Reference_Technical_Name_Depend:['',[]],
        Reference_Table_Name_Depend:['',[]],
        Category_Group_Depend:['',[]],
        Hive_Execution_Commands_Depend:['', []],
      //-------------------------   
      Approval_Required:['', []],
      Completed:['', []],
      Completed_Date:['', []],
      Completed_Msg:['', []],
      Declined:['', []],
      Declined_Date:['', []],
      Declined_Msg:['', []],
      Dependent:['', []],
      Dependent_Date:['', []],
      Dependent_Msg:['', []],
      Failed:['', []],
      Failed_Date:['', []],
      Failed_Msg:['', []],
      File_Format:['', []],
      File_Name:['', []],
      File_Path:['', []],
      In_Progress:['', []],
      In_Progress_Date:['', []],
      In_Progress_Msg:['', []],
      Landing_Dir_Dev:['', []],
      Landing_Dir_SIT:['', []],
      //Reference_Technical_Name:['', []],
      Status:['', []],
      Total_No_Of_Lines:['', []],
      UI_File_Name:['', []],
      File_Extension:['',[]],
      New_File:['',[]]
    });
      
    //Reference Management
      this.editRefForm=this.formBuilder.group({
        Reference_Name: ['', [Validators.required, Validators.maxLength(50)]],
        Reference_Technical_Name: ['', [Validators.required, Validators.maxLength(50)]],
        File_Header: ['',[]],
        Delimiter:['',[Validators.maxLength(1)]],
        Need_Header: ['', []],
        Reference_Hivetable_Name:['', [Validators.maxLength(100)]],
        Date_Position:['',Validators.required],
        Owner: [this.ownerName, Validators.required],
        Approver: [this.selectedItems, Validators.required],//Collect_AI_Pset_Dev
        Category_Group :['',[]],
        Disabled: ['',[]],
        enable_job:['',[]],
        Landing_Dir_Prod:['',[]],
        Collect_AI_Pset_Prod:['',[]],
        Collect_Execution_Commands_Dev:['',[]],
        Collect_Server_Prod:['',[]],
        Hive_AI_Pset_Prod:['',[]],
        Hive_Execution_commands_Dev:['',[]],
        Edge_Server_Hive_Prod:['',[]],
        Dependency:['',[]],
        Reference_Name_Depend:['',[]],
        Reference_Technical_Name_Depend:['',[]],
        Reference_Table_Name_Depend:['',[]],
        Category_Group_Depend:['',[]],
        Hive_Execution_Commands_Depend:['', []],       
        
      });


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_text',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

 

  // convenience getter for easy access to form fields
  get formControls() {
    return this.updateDetailsForm.controls;
  }

  // convenience getter for easy access to form fields
  get addFormControls() {
    return this.addUserForm.controls;
  }

  // Reference mngmt convenience getter for easy access to form fields
  get editRefFormControls() {
    return this.editRefForm.controls;
  }
  // Reference mngmt convenience getter for easy access to form fields
  get addRefFormControls() {
    return this.addReferenceForm.controls;
  }

  addNew() {
    this.addUserModal();
  }

  onSubmitTest() {
    
    this.submitted = true;
 // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.updateDetailsForm.value));
    // stop here if form is invalid
    if (this.updateDetailsForm.invalid) {
      return;
    }
    let data = this.updateDetailsForm.value;
    data.username = JSON.parse(sessionStorage.getItem("user")).UserName;
    data.update_user_id = this.userId;
    this.getEditAdminUsers(data);
   

  }

  disableUser() {

    this.deactivateUser(this.user_name)

  }

  enableUser() {

    this.activateUser(this.user_name)

  }

  //Get llist of all users  35.170.68.215:8082/api/update_user
  getAllAdminUsersList() {
    this.loaderService.display(true);
    this.adminService.getAdminUsersList().subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.allUserMessage = data["Message"];
          this.userObjet = this.allUserMessage[this.item]
          this.setValuesInForm(this.allUserMessage[this.item]);
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }


  //35.170.68.215:8082/api/update_user
  deactivateUser(user_id) {
    this.loaderService.display(true);
    this.loader = true;
    this.adminService.disableUser(user_id).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.toasterService.success("Successfully disabled");
          this.getAllAdminUsersList();
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
        this.loader = false;
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
        this.loader = false;
      }
    );
  }

  activateUser(user_id) {
    this.loaderService.display(true);
    this.loader = true;
    this.adminService.enableUser(user_id).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.toasterService.success("Successfully enabled");
          this.getAllAdminUsersList();
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
        this.loader = false;
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
        this.loader = false;
      }
    );
  }

  //url 35.170.68.215:8082/api/update_user
  getEditAdminUsers(jsonData) {
    this.loaderService.display(true);
    this.adminService.adminUpdateUser(jsonData).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.toasterService.success("Successfully Updated");
          this.getAllAdminUsersList();
          this.setValuesInForm(this.allUserMessage[this.item]);
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  //select item from list
  onSelected(userObject, index) {

    this.item = index;

    this.userObjet = userObject;

    this.setValuesInForm(this.userObjet);
  }

  //Set values to deatils form
  setValuesInForm(userObjectMessage) {
    this.updateDetailsForm.controls.first_name.setValue(userObjectMessage.first_name);
    this.updateDetailsForm.controls.last_name.setValue(userObjectMessage.last_name);
    this.updateDetailsForm.controls.email.setValue(userObjectMessage.email);
    this.updateDetailsForm.controls.phone.setValue(userObjectMessage.phone);
    this.updateDetailsForm.controls.role.setValue(userObjectMessage.role);
    this.updateDetailsForm.controls.disabled.setValue(userObjectMessage.disable);
    this.userId = userObjectMessage.id.toString();
    this.userRole = userObjectMessage.role;
    this.user_name = userObjectMessage.user_name;
    this.profileRole = userObjectMessage.profile;
    this.sectionRole = userObjectMessage.section;
    this.updateDetailsForm.controls.profile.setValue(this.profileRole);
    this.updateDetailsForm.controls.section.setValue(this.sectionRole);
    
  }
  searchBusinessTerm() { }

  //add modal

  addUserModal() {
    this.submitted = false;

    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      centered: true,
    };

    this.modalService.open(this.addUser, ngbModalOptions);
  }

  // on submit of Add form
  onSubmitAddUser() {
    this.submittedAddModal = true;

    // stop here if form is invalid
    if (this.addUserForm.invalid) {
      return;
    }
    let dataAddform = this.addUserForm.value;
    dataAddform.username = JSON.parse(sessionStorage.getItem("user")).UserName;
    
     //this.getAddAdminUsers(dataAddform);
    // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(dataAddform));
  }

  

  //url 35.170.68.215:8082/api/add_user 
  getAddAdminUsers(jsonData) {
    this.loaderService.display(true);
    this.adminService.adminAddUser(jsonData).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.toasterService.success("Successfully Updated");
          this.getAllAdminUsersList();
          this.modalService.dismissAll();
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }
  //Ref managemennt 

  //s  35.170.68.215:8082/api/ViewList
  getAllReferenceList() {
    this.loaderService.display(true);
    this.adminService.getViewListList().subscribe(
      (data) => {
        if (data["Success"] > 0) {
           this.refManagementMessage = data["Message"];
           this.refManagementOwnerList = data["owner_list"];  
           
           this.setValReferenceFrom(this.refManagementMessage[this.item1]._source,this.item1);
           this.cd.detectChanges();
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  onReferenceSubmit(){
    this.submittedReferenceManagement = true;
    
    // stop here if form is invalid
    if (this.editRefForm.invalid) {
      return;
    }
    this.saveReferenceData(this.editRefForm.value,this.refId);
  }

  //select item from list
  onSelectedRefMngmt(_sourceObj, indexVal) {

    this.setValReferenceFrom(_sourceObj,indexVal)
    this.item1=indexVal
  }

  //Set values to deatils form
  setValReferenceFrom(refObjSource,index) {
    this.selectedItems=refObjSource.Approver;
        //set values to variables
    this.refId=this.refManagementMessage[index]._id
    this.ownerName=this.refManagementMessage[index]._source['Owner'];
    
    this.editRefForm.controls.Reference_Name.setValue(refObjSource.Reference_Name);
    this.editRefForm.controls.Reference_Technical_Name.setValue(refObjSource.Reference_Technical_Name);
    this.editRefForm.controls.File_Header.setValue(refObjSource.File_Header);
    if(refObjSource.Need_Header == ''){
      this.editRefForm.controls.Need_Header.setValue('N');
    }else{
      this.editRefForm.controls.Need_Header.setValue(refObjSource.Need_Header);
    }
    
    this.editRefForm.controls.Delimiter.setValue(refObjSource.Delimiter);
    this.editRefForm.controls.Reference_Hivetable_Name.setValue(refObjSource.Reference_Hivetable_Name);
    this.editRefForm.controls.Date_Position.setValue(refObjSource.Date_Position);
    this.editRefForm.controls.Owner.setValue(refObjSource.Owner);
    this.editRefForm.controls.Approver.setValue(this.selectedItems);
    this.editRefForm.controls.Category_Group.setValue(refObjSource.Category_Group);
    //Need to check
    if(refObjSource.Disabled == ''){
      this.editRefForm.controls.Disabled.setValue('Y');
    }else{
      this.editRefForm.controls.Disabled.setValue(refObjSource.Disabled);
    }
    if(refObjSource.enable_job == ''){
      this.editRefForm.controls.enable_job.setValue('N');
    }else{
      this.editRefForm.controls.enable_job.setValue(refObjSource.enable_job);
    }
    this.editRefForm.controls.Landing_Dir_Prod.setValue(refObjSource.Landing_Dir_Prod);
    this.editRefForm.controls.Collect_AI_Pset_Prod.setValue(refObjSource.Collect_AI_Pset_Prod);
    this.editRefForm.controls.Collect_Execution_Commands_Dev.setValue(refObjSource.Collect_Execution_Commands_Dev);
    this.editRefForm.controls.Collect_Server_Prod.setValue(refObjSource.Collect_Server_Prod);
    this.editRefForm.controls.Hive_AI_Pset_Prod.setValue(refObjSource.Hive_AI_Pset_Prod);
    this.editRefForm.controls.Hive_Execution_commands_Dev.setValue(refObjSource.Hive_Execution_commands_Dev);
    this.editRefForm.controls.Edge_Server_Hive_Prod.setValue(refObjSource.Edge_Server_Hive_Prod);
    if(refObjSource.Dependency == ''){
      this.editRefForm.controls.Dependency.setValue('N');
    }else{
      this.editRefForm.controls.Dependency.setValue(refObjSource.Dependency);
    }
    this.editRefForm.controls.Reference_Name_Depend.setValue(refObjSource.Reference_Name_Depend);
    this.editRefForm.controls.Reference_Technical_Name_Depend.setValue(refObjSource.Reference_Technical_Name_Depend);
    this.editRefForm.controls.Reference_Table_Name_Depend.setValue(refObjSource.Reference_Table_Name_Depend);
    this.editRefForm.controls.Category_Group_Depend.setValue(refObjSource.Category_Group_Depend);
    this.editRefForm.controls.Hive_Execution_Commands_Depend.setValue(refObjSource.Hive_Execution_Commands_Depend);
  }

   //s  35.170.68.215:8082/api/ViewList
   saveReferenceData(dataReq,refId) {
    this.loaderService.display(true);
    this.adminService.referenceEditData(dataReq,refId).subscribe(
      (data) => {
        if (data["Success"] > 0) {
           this.toasterService.success(data["Message"]);

           // added delay of 1 sec before getting the list again
           setTimeout(() => {
            this.getAllReferenceList();
           }, 1000);

        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }


  changeTab(rName){

    if(rName=="Reference Services"){
      this.servicesMessage=this.servicesRefMessage;
      this.setValServicesFrom(this.servicesRefMessage[0],0);
    }else if(rName=="Backend"){
      this.servicesMessage=this.servicesUiMessage;
      this.setValServicesFrom(this.servicesUiMessage[0],0);
    }else{
      this.servicesMessage=this.servicesAllMessage;
      this.setValServicesFrom(this.servicesMessage[0],0);
    }
    
  }

  tabClick(event) {
    const tab = event.tab.textLabel;
    if(tab=="Services Management"){
      this.getAllServicesList()
    }
  }
  
  getAllServicesList() {
    this.loaderService.display(true);
    this.adminService.getServicesList().subscribe(
      (data) => {
        if (data["Success"] > 0) {

            this.servicesMessage = data["Message"];
            this.servicesMessage.forEach((value) => {
              if(value.category=="Reference"){
              this.servicesRefMessage.push(value);
               }else if(value.category=="Backend"){
                this.servicesUiMessage.push(value);
               }else{
                this.servicesAllMessage=data["Message"];
               }
            })
                    
          this.setValServicesFrom(this.servicesMessage[this.itemServices],this.itemServices);
           this.cd.detectChanges();
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  //select item from list
  onServiceSelected(obj, index) {
    this.setValServicesFrom(obj,index);
    this.itemServices=index
  }

  setValServicesFrom(obj,index) {
   this.servicesObj=obj;
   this.itemServices=index
  // this.category=this.

  }

  onStart(serviceName){
    this.startServices(serviceName)
  }

  startServices(serviceName) {
    this.loaderService.display(true);
    this.adminService.getStartServices(serviceName).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.toasterService.success(data["Message"]);
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  addNewRef() {
    this.addRefModal();
  }

  //add modal

  addRefModal() {
    this.submitted = false;

    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      centered: true,
    };

    this.modalService.open(this.addReference, ngbModalOptions);
  }

   // on submit of Add form
   onSubmitAddRef() {
    this.submittedAddRef = true;
    
    // stop here if form is invalid
    if (this.addReferenceForm.invalid) {
      return;
    }
    this.addReferenceForm.controls.New_File.setValue(1);
    this.getRefAddAdmin(this.addReferenceForm.value)
  }

  onFileChange1($event) {
    let filePath = $event.target.files[0]; // <--- File Object for future use.

    this.fileName=filePath ? filePath.name : ''
    
    this.addReferenceForm.controls['file'].setValue(filePath);
   // this.addReferenceForm.controls['file'].setValue(filePath ? filePath.name : ''); // <-- Set Value for Validation
    this.fileUploadDone=true;
  } 


  onFileChange(event) {
    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        this.addReferenceForm.patchValue({
          file: reader.result
       });
      
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  chnageFile(){
    this.fileUploadDone=false;
    this.fileName='';
  }

  onChangeEditReferenceTechnicalName(value) {
    if(value.includes("YYYYMMDD")||value.includes("yyyymmdd")){
      if(value.includes("YYYYMMDD_") || value.includes("yyyymmdd_")){
        this.editRefForm.controls.Date_Position.setValue("1");
      }else if(value.includes("_YYYYMMDD")|| value.includes("_yyyymmdd")){
        this.editRefForm.controls.Date_Position.setValue("2");
      }else if((value.includes("_YYYYMMDD")|| value.includes("_yyyymmdd"))||(value.includes("YYYYMMDD_")|| value.includes("yyyymmdd_"))){
        this.editRefForm.controls.Date_Position.setValue("0");
      }
    }else{
      this.editRefForm.controls.Date_Position.setValue("");
      
    }

    
  }

  onChangeAddReferenceTechnicalName(valueAdd) {
    if(valueAdd.includes("YYYYMMDD")||valueAdd.includes("yyyymmdd")){
      if(valueAdd.includes("YYYYMMDD_") || valueAdd.includes("yyyymmdd_")){
        this.addReferenceForm.controls.Date_Position.setValue("1");
      }else if(valueAdd.includes("_YYYYMMDD")|| valueAdd.includes("_yyyymmdd")){
        this.addReferenceForm.controls.Date_Position.setValue("2");
      }else if((valueAdd.includes("_YYYYMMDD")|| valueAdd.includes("_yyyymmdd"))||(valueAdd.includes("YYYYMMDD_")|| valueAdd.includes("yyyymmdd_"))){
        this.addReferenceForm.controls.Date_Position.setValue("0");
      }
    }else{
      this.addReferenceForm.controls.Date_Position.setValue("");
    }   
  }

   //Get llist of all users  35.170.68.215:8082/api/add_data
   getRefAddAdmin(data) {
    this.loaderService.display(true);
    this.adminService.getRefAddData(data).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.toasterService.success(data["Message"]);
          // added delay of 1 sec before getting the list again
          setTimeout(() => {
            this.getAllReferenceList();
           }, 1000);
           this.modalService.dismissAll();
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  searchUserName() {
    // if (this.userSearch.value == "") {
    //   //this.getUserSearchData();
    //   this.getAllAdminUsersList();
    // }
    // else {
      this.loaderService.display(true);
      this.adminService.getSearchUsersList(this.userSearch.value).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          if(data["Message"]=="No Data Found"){
            this.toasterService.error("No Data Found");
            this.loaderService.display(false);
            this.getAllAdminUsersList();
          }else{
          this.allUserMessage = data["Message"];
          this.userObjet = this.allUserMessage[this.item]
          this.setValuesInForm(this.allUserMessage[this.item]);
          }
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );

  //  }
   }

}
