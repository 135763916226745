import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormValidationService } from './services/formvalidation.service';
@Component({
  selector: 'error-messages',
  template: `<span style="color:red ; font-family: HelveticaRegular; font-size:12px" *ngIf="errorMessage !== null">{{errorMessage}}</span>`
})
export class ErrorMessagesComponent {
  @Input() control: FormControl;
  constructor() { }
  get errorMessage() {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return FormValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }    
    return null;
  }
}