import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as Chart from 'chart.js';
import { LoaderService } from 'src/app/services/loaderService';
import { DataProfileService } from 'src/app/shared/services/dataProfile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-data-profiling-details',
  templateUrl: './data-profiling-details.component.html',
  styleUrls: ['./data-profiling-details.component.scss']
})
export class DataProfilingDetailsComponent implements OnInit {
  pieChartProfile1: any
  tableDetails: Array<any>;
  tableName: any;
  dataElement:any;
  filterDate:any;
  responseElementCount:any
  collectionDate: string;
  dataElements: Array<any>;
  selectedDataElement: any;

  statusData: Array<any>;
  selectedstatus: any;

  //testing list
  responseAnalysisList:Array<any> = [];
  responseCommPatternList:any;
  
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private dataProfileService: DataProfileService,
    private activatedRoute: ActivatedRoute,
    private _location: Location) { }

  ngOnInit() {
    this.dataElements = [];
    this.statusData=[];
    this.tableName=this.activatedRoute.snapshot.paramMap.get("table_Name");
    this.collectionDate=this.activatedRoute.snapshot.paramMap.get("date");
    this.dataElement=this.activatedRoute.snapshot.paramMap.get("element_name");
    this.filterDate=this.activatedRoute.snapshot.paramMap.get("filter_date");
    
    this.getDataProfileDetailsElement(this.tableName,this.dataElement)
    this.getDataProfileValueAnalysis(this.tableName,this.dataElement)
    this.getDataProfileCommPattern(this.tableName,this.dataElement)

    //Mock start f
    this.responseAnalysisList.forEach(element1 => {
      if (!(this.dataElements.includes(element1['value_pattern']))) {
        this.dataElements.push(element1['value_pattern']);
      }
    })

    this.responseAnalysisList.forEach(element2 => {
      if (!(this.statusData.includes(element2['status']))) {
        this.statusData.push(element2['status']);
      }
    })

    //Mock end
  }


  redirectBack() {
    this._location.back();
  }

     //get profile summery values 
    getDataProfileDetailsElement(tableName,dataElement) {
      this.loaderService.display(true);
      this.dataProfileService.getDataElementCount(tableName,dataElement, this.filterDate).subscribe(
          (data) => {
            if (data["Success"] > 0) {
              this.responseElementCount = data["Message"];
              this.responseElementCount.forEach((element) => {
               this.pieChartProfile1.data.datasets[0].data=[element.valid_percent,element.invalid_percent];
  
              });
              this.pieChartProfile1.update();

            } else {
              this.toasterService.error(data["Message"]);
            }
            this.loaderService.display(false);
          },
          (error) => {
            this.toasterService.error("Something went wrong!");
          }
        );
     }

     getDataProfileValueAnalysis(tableName,dataElement) {
      this.loaderService.display(true);
      this.dataProfileService.getValueAnalysisList(tableName,dataElement, this.filterDate).subscribe(
          (data) => {
            if (data["Success"] > 0) {
              this.responseAnalysisList = data["Message"];
              this.responseAnalysisList.forEach(element1 => {
                if (!(this.dataElements.includes(element1['value_pattern']))) {
                  this.dataElements.push(element1['value_pattern']);
                }
              })
          
              this.responseAnalysisList.forEach(element2 => {
                if (!(this.statusData.includes(element2['status']))) {
                  this.statusData.push(element2['status']);
                }
              })
            
            } else {
             
              this.toasterService.error(data["Message"]);
            }
            this.loaderService.display(false);
          },
          (error) => {
            
            this.toasterService.error("Something went wrong!");
          }
        );
     }

     getDataProfileCommPattern(tableName,dataElement) {
      this.loaderService.display(true);
      this.dataProfileService.getCommonPatternList(tableName,dataElement, this.filterDate).subscribe(
          (data) => {
            if (data["Success"] > 0) {
              this.responseCommPatternList = data["Message"];
            
            } else {
              this.toasterService.error(data["Message"]);
            }
            this.loaderService.display(false);
          },
          (error) => {
            this.toasterService.error("Something went wrong!");
          }
        );
     }
    
  ngAfterViewInit() {
    
    this.pieChartProfile1 = new Chart('pieChartProfile1', {
      type: 'pie',
      data: {
        datasets: [{
          data: [],
          backgroundColor: ["#44B88F","#FF0000"],
          borderColor: ["#44B88F","#FF0000"],
        }],
        labels:["VALID","INVALID"]
      },

      options: {
        maintainAspectRatio: false,
        cutoutPercentage: 0,
        title: {
          display: true,
          text: '',
          position: 'bottom',
          fontColor: '#929eae',
          fontFamily: '"HelveticaRegular"',
          fontSize: 14,
          padding: 10,
          //fontWeight:500,
        },
        legend: {
          display: true,
          position: 'bottom',
          onClick: (e) => e.stopPropagation(),
          labels: {
            padding: 18,
            fontColor: '#373737',
            fontFamily: '"HelveticaBold"',
            fontSize: 12,
            //fontWeight:500,
            
          }
        },

        tooltips: {
          enabled: true,
        }
      },
    });

 }

}
