import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ReferenceService {
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(
    private http: HttpClient
  ) { }

  getReferenceList(pageNo, pageSize) {

    let url = this.baseApiUrl + "ViewList";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        page_no: pageNo,
        page_Size: pageSize
       });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getRefStatusHistory(refName){
    let url = this.baseApiUrl + "status_history";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        reference_name: refName,
      });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getDelegationUsersList(){
    let url = this.baseApiUrl + "get_all_user";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName });
      
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  doDelegation(startDate, endDate, userName){
    let url = this.baseApiUrl + "delegate";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        delegate_to: userName,
        delegation_start: startDate,
        delegation_end: endDate });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
  
}
