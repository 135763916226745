import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'dataElement'
})
export class DataElementPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      if (item.element_name != null) {
        return item.element_name.toLowerCase().includes(searchText);
      }
    });
  }
}