import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/loginService';
import { LoaderService } from './../services/loaderService';
import { ToastrService } from 'ngx-toastr';
import { SessionStorageService } from '../services/sessionStorage';
import { User } from '../models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading: boolean;
  loggedInUser: User;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService,
    private loginService: LoginService,
    private loaderService: LoaderService,
    private sessionStorageService: SessionStorageService
  ) {
    this.loginForm = this.formBuilder.group({
      'userEmail': ['', [Validators.required]],
      'password': ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.isLoading = false;
    sessionStorage.clear();
  }

  signIn() {

    this.loaderService.display(true);
    this.isLoading = true;

    this.loginService.login(this.loginForm.controls['userEmail'].value, this.loginForm.controls['password'].value).subscribe((response: any) => {

      if (response['Success'] > 0) {

        if (response.Disable == 'NO') {
          this.sessionStorageService.saveToken(response['Token']);
          this.sessionStorageService.saveLoggedInUser(response);
          this.loggedInUser = new User();
          this.loggedInUser.assign(response);
          this.toasterService.success('Login Successful');
          this.router.navigate(['/user/home']);
          this.loaderService.display(false);
          this.isLoading = false;
        }
        else{
          this.loginForm.reset();
          this.toasterService.error('User is disabled');
          this.loaderService.display(false);
          this.isLoading = false;
        }

      }
      else {
        this.loginForm.reset();
        this.toasterService.error('Invalid Credentials');
        this.loaderService.display(false);
        this.isLoading = false;
      }
    },
      error => {
        this.loginForm.reset();
        this.loaderService.display(false);
        this.isLoading = false;
        sessionStorage.clear();
        this.router.navigate(['/login']);
      });

  }

}
