import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { SelectAutocompleteComponent } from "mat-select-autocomplete";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LoaderService } from "src/app/services/loaderService";
import { ToastrService } from "ngx-toastr";
import { BusinessTermService } from "src/app/shared/services/businessTerm.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { MatDialog } from "@angular/material";
import { Location } from '@angular/common';
import { DataDictionaryService } from 'src/app/shared/services/dataDictionary.service';
import { SharedService } from 'src/app/shared/services/shared-service.service';

@Component({
  selector: "app-data-dictionary-detail",
  templateUrl: "./data-dictionary-detail.component.html",
  styleUrls: ["./data-dictionary-detail.component.scss"],
})
export class DataDictionaryDetailComponent implements OnInit {
  @ViewChild("editDataDictionary", { static: true }) public editDataDictionary: TemplateRef<any>;
  @ViewChild(SelectAutocompleteComponent, { static: false }) multiSelect: SelectAutocompleteComponent;
  @ViewChild("editSuccess", { static: true }) public editSuccess: TemplateRef<any>;
 
  @ViewChild("editTableDetail", { static: true }) public editTableDetail: TemplateRef<any>;

  user:any;
  editDDDetailForm: FormGroup;
  editTableDetailForm: FormGroup;
  editTDDetailForm: FormGroup;
  submitted = false;
  submittedTD = false;
  dataDictionaryTermName: string;
  contentResult: Array<any> = [];
  editPageDeatilsMessage: Array<any> = [];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  changeHistoryList: Array<any> = [];
  relatedTablesList: Array<any> = [];
  tableProfileList: Array<any> = [];

  relatedTableList: Array<any> = [
    { id: "Barbecue", text: "Barbecue" },
    { id: "Barbecue", text: "Mustard" },
    { id: "Barbecue", text: "Ketchup" },
    { id: "Barbecue", text: "Mayonaise" },
  ];
  //businessOwnerList: Array<any> = [];
  tableOwnerList: Array<any> = [];
  referenceFrequencyList:Array<any> = [];
  dataStewardList: Array<any> = [];
  businessTermDomain1: Array<any> = [];
  cityName: any;
  termTaxonomyCategory: Array<any> = [];
  termTaxonomyTermType: Array<any> = [];
  termTaxonomySubCategory: Array<any> = [];
  data_dictionary_id_updated = [];
  responseMessage: Array<any> = [];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  relatedTableSelectedItems = [];
  relatedTableDropdownSettings = {};
  usage_rules = [];
  businessOwnerValue: string;
  dataStewardValue: string;

  constructor(
    private router: Router,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private dataDictionaryService: DataDictionaryService,
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));

    this.activatedRoute.params.subscribe((params: Params) => {
      this.dataDictionaryTermName = params["id"];
      this.getDataDictionaryDetailsData();
      //this.getDataDictionaryPreEditData(this.dataDictionaryTermName,"data_dictionary");
    });

    this.editDDDetailForm = this.formBuilder.group({
      description: ["", [Validators.required,Validators.maxLength(200)]],
      feed_name: ["", [Validators.required,Validators.maxLength(50)]],
      source: ["", [Validators.required,Validators.maxLength(50)]],
      file_format: ["", [Validators.required,Validators.maxLength(50)]],
      table_owner: [this.responseMessage['table_owner'], [Validators.required]],
      refresh_frequency:[this.responseMessage['refresh_frequency'], [Validators.required]],
      status:[this.responseMessage['status'], [Validators.required]],      
    }); 

    this.editTDDetailForm = this.formBuilder.group({
      description: ["", [Validators.required,Validators.maxLength(3001)]],
      reference_used: ["", [Validators.required,Validators.maxLength(3001)]],
      rule_type: ["", [Validators.required,Validators.maxLength(3001)]],
      business_rules: ["", [Validators.required,Validators.maxLength(3001)]]
    }); 
  }


  edit() {
    this.getDataDictionaryPreEditData(this.dataDictionaryTermName,"data_dictionary");
    //this.dataDictionaryEdit();
  }

  redirectBack() {
    this._location.back();
  }

  dataDictionaryEdit() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };

    this.editDDDetailForm.controls.description.setValue(this.responseMessage['description']);
    this.editDDDetailForm.controls.feed_name.setValue(this.responseMessage['feed_name']);
    this.editDDDetailForm.controls.source.setValue(this.responseMessage['source']);
    this.editDDDetailForm.controls.file_format.setValue(this.responseMessage['file_format']);
    this.editDDDetailForm.controls.table_owner.setValue(this.responseMessage['table_owner']);
    this.editDDDetailForm.controls.refresh_frequency.setValue(this.responseMessage['refresh_frequency']);
    this.editDDDetailForm.controls.status.setValue(this.responseMessage['status']);
   
    this.modalService.open(this.editDataDictionary, ngbModalOptions);
  }

  //API call for Deatils
  getDataDictionaryDetailsData() {
    this.loaderService.display(true);
    this.dataDictionaryService.getDataDictionaryDetailsData(this.dataDictionaryTermName).subscribe(
      (data) => {
        
        if (data["Success"] > 0) {
          this.contentResult = data["Message"];

          this.changeHistoryList = data["Change_History_Data"];
          this.relatedTablesList = data["Related_Tables_Data"];
          this.tableProfileList = data["Table_Profile_Data"];
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  //API call for Business Onwer,Data Steward and others fields
    getDataDictionaryPreEditData(mId,indexName) {
        this.loaderService.display(true);
        this.dataDictionaryService.getDataDictionaryEditPageDetails(mId,indexName).subscribe(
          (data) => {
            if (data["Success"] > 0) {
              this.responseMessage = data["Message"];
              this.tableOwnerList=this.responseMessage["table_owner_list"]
              this.referenceFrequencyList=this.responseMessage["reference_frequency_list"]
              this.dataDictionaryEdit();
            } else {
              this.toasterService.error(data["Message"]);
            }
            this.loaderService.display(false);
          },
          (error) => {
            this.toasterService.error("Something went wrong!");
          }
        );
      }

      //API call for Business Onwer,Data Steward and others fields
    getDataDictionaryPreEditTable(mId,indexName) {
      this.loaderService.display(true);
      this.dataDictionaryService.getDataDictionaryEditPageDetails(mId,indexName).subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.responseMessage = data["Message"];
            setTimeout(() => {
              this.tableDeatilsEdit();
             }, 500);
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
    }

  //API call for edit filled
  getDataDictionaryEditData(data) {
    this.loaderService.display(true);

    this.dataDictionaryService.getEditData(this.dataDictionaryTermName, data,"data_dictionary")
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.getDataDictionaryDetailsData()
            //this.getDataDictionaryPreEditData(this.dataDictionaryTermName,"data_dictionary");
            this.modalService.dismissAll();
            this.toasterService.success('Data Dictionary updated successfully.');
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  //API call for Tbale deatisl edit filled
  getDataDictionaryTableDeatilEditData(mId,data,indexName) {
    this.loaderService.display(true);

    this.dataDictionaryService.getEditData(mId, data,indexName)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.getDataDictionaryDetailsData()
           // this.getDataDictionaryPreEditData(this.dataDictionaryTermName,"data_dictionary");
            this.modalService.dismissAll();
            this.toasterService.success('Data Dictionary updated successfully.');
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  // convenience getter for easy access to form fields
  get formControls() {
    return this.editDDDetailForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editDDDetailForm.invalid) {
      return;
    }
   // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.editDDDetailForm.value));
    //call the edit api
    this.getDataDictionaryEditData(this.editDDDetailForm.value);
  }

  close() {
    this.modalService.dismissAll();
  }

  //open Data Lineage
  openDataLineage(id){
  this.router.navigate(["user/dataLineage/details/", id]);
  }

  dataProfiling(id){
    this.router.navigate(["user/dataDictionary/dataProfiling/", id]);
  }

  //open Data Lineage
  openDataProfilingQuality(id){
    this.router.navigate(["user/dataProfiling/", id]);
    }

    openDetail(table_Name, date) {
      this.sharedService.setCollectionDate(date);
      this.router.navigate(["user/dataQuality/details/", table_Name]);
    }

  
    viewEdit(rId){
      this.getDataDictionaryPreEditTable(rId,"data_dict_table_details");
    }

    onSubmitTableDeatils(){
      this.submittedTD = true;

    // stop here if form is invalid
    if (this.editTDDetailForm.invalid) {
      return;
    }
   // display form values on success
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.editDDDetailForm.value));
    //call the edit api
    this.getDataDictionaryTableDeatilEditData(this.responseMessage['elastic_id'],this.editTDDetailForm.value,"data_dict_table_details");
      
    }
   
  //-----------------------------------------------------
  tableDeatilsEdit() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    
    this.editTDDetailForm.controls.description.setValue(this.responseMessage['description']);
    this.editTDDetailForm.controls.reference_used.setValue(this.responseMessage['reference_used']);
    this.editTDDetailForm.controls.rule_type.setValue(this.responseMessage['rule_type']);
    this.editTDDetailForm.controls.business_rules.setValue(this.responseMessage['business_rules']);
  
    this.modalService.open(this.editTableDetail, ngbModalOptions);
  }
   // convenience getter for easy access to form fields
   get formControls1() { return this.editTDDetailForm.controls; }
  //----------------------------------------------------
}
