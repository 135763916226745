import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDirective } from './directives/drag-drop.directive';
import { HighlightDirective } from './directives/drag-drop.directive';
import { NgxPaginationModule } from 'ngx-pagination';

//Pipes
import { RemoveHyphonPipe } from './../pipes/removehyphon.pipe';
import { FilterPipe } from './../pipes/filter.pipe';
import { ReferencePipe } from '../pipes/reference.pipe';
import { DQMetricPipe } from '../pipes/dqMetric.pipe';
import { DataQualityPipe } from '../pipes/dataquality.pipe';
import { TableDetailsDpPipe } from '../pipes/tableDetailsDp.pipe';
import { ValueAnalysisDpPipe } from '../pipes/valueAnalysisDp.pipe';
import { StatusDpPipe } from '../pipes/statusDp.pipe';
import { DataElementPipe } from '../pipes/dataElement.pipe';
import { AdminServicesPipe } from '../pipes/adminservices.pipe';
import { DqDatePipe } from '../pipes/dqDate.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    DragDirective,
    HighlightDirective,
    RemoveHyphonPipe,
    FilterPipe,
    ReferencePipe,
    AdminServicesPipe,
    DQMetricPipe,
    DataQualityPipe,
    TableDetailsDpPipe,
    ValueAnalysisDpPipe,
    StatusDpPipe,
    DataElementPipe,
    DqDatePipe
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    NgxPaginationModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    DragDirective,
    HighlightDirective,
    NgxPaginationModule,
    RemoveHyphonPipe,
    FilterPipe,
    ReferencePipe,
    AdminServicesPipe,
    DQMetricPipe,
    DataQualityPipe,
    TableDetailsDpPipe,
    ValueAnalysisDpPipe,
    StatusDpPipe,
    DataElementPipe,
    DqDatePipe
  ]
})
export class SharedModule { }
