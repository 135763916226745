export class User {

  enabled: boolean;
  profile: Profile;
  authorities : any;
  role: any;
  username: any;

  assign(json: any) {
    if (json) {
      Object.assign(this, json);
      this.profile = new Profile();
      if (json.profile) {
        this.profile.assign(json.profile);
      }

    }
  }
};

export class Profile {
  name: string;
  constructor() { }
  assign(json: any) {
    if (json) {
      Object.assign(this, json);
    }
  }
}
