import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  public saveToken(auth) {
    sessionStorage.setItem('token', auth);
  }
  public getToken() {
    let token = null;
    const tokenStr = sessionStorage.getItem('token');
       
    if (tokenStr) {
      token = tokenStr;
    }
    return token;
  }

  public getLoggedInUser() {
    var user = null;
    const userStr = sessionStorage.getItem('user');
    if (userStr) {
      user = new User();
      user.assign(JSON.parse(userStr));
    }

    return user;
  }
  public saveLoggedInUser(user: User) {
    sessionStorage.setItem('user', JSON.stringify(user));

  }

}
