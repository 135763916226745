import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from './sessionStorage';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  baseApiUrl: string = environment.apiBaseUrl;
  loggedInUser: User;
  isUserLoggedIn: boolean = false;
  token: any;

  login(username, password) {

    let url = this.baseApiUrl + 'users';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.httpClient.post(url, JSON.stringify({ "username": username, "password": password }), httpOptions).pipe(
      map((response: Response) => {
        this.isUserLoggedIn = true;
        return response;
        // return {
        //   "Disable": "NO", 
        //   "Email": "shashank.singh@dgliger.com", 
        //   "Error": 0, 
        //   "First_Name": "Aditya", 
        //   "Last_Name": "singh", 
        //   "Message": "Successfully Login", 
        //   "Phone": "9958403705", 
        //   "Profile": "All", 
        //   "Role": "Admin", 
        //   "Section": "All", 
        //   "SessionId": "bfd3ff41-06f7-4a3c-937c-d17576a59bbc", 
        //   "Success": 1, 
        //   "Token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MzQsImV4cCI6MTYwNDQ2MzcwNi45NjYxNzUzfQ.rqcUnZTx2RTMfWKSqJ60p8dSjvBVFSxHj43o-TRAF80", 
        //   "UserName": "shashank"
        // };
      }))
  }

  logout() {

    let url = this.baseApiUrl + 'logout';

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName
    });

    return this.httpClient.post(url, postBody).pipe(
      map((response: Response) => {
        this.isUserLoggedIn = false;
        return response;
      }))
  }

  constructor(private httpClient: HttpClient, private sessionStorageService: SessionStorageService, private router: Router) {
    this.loggedInUser = this.sessionStorageService.getLoggedInUser();
  }
}