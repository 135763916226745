import { Component, OnInit, HostBinding,ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoaderService } from './services/loaderService';
import { LoginService } from './services/loginService';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showLoader: boolean;
  showHeader: boolean;
  title = 'Reference Portal TSEL';
  
    constructor( 
    private loginService: LoginService,   
    private router: Router,
    private  LoaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private toasterService: ToastrService,
    ) { 
  }
  ngOnInit() {
    if(sessionStorage.getItem('user')==null){
      sessionStorage.clear();
      this.loginService.isUserLoggedIn = false;
      this.router.navigate(['/login']);
      this.LoaderService.display(false);
      
    }
    
    this.showLoader = true;

    this.LoaderService.status.subscribe((val: boolean) => {
      setTimeout(_ => this.showLoader = val);  
      
  });

  this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
}
   ngAfterViewInit() {   
    this.cdr.detectChanges();
  }
}
