import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataDictionaryService {
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  public getToken() {
    let token = null;
    const tokenStr = sessionStorage.getItem("token");
    if (tokenStr) {
      token = tokenStr;
    }
    return token;
  }

  //Get list of DatacDictionary.
  getDataDictionaryList(pageNo, pageSize) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "index_data_list";
    //let token = this.getToken();
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name: "data_dictionary",
        page_no: pageNo,
        page_Size: pageSize
      });
     
      return this.http.post(url, postBody,).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

 //Detail Data Dictionary
  getDataDictionaryDetailsData(mId) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "global_search_details";
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:"data_dictionary",
        _id:mId
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

 //EDIT Deatils BT
 getDataDictionaryEditPageDetails(mId,indexName) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "edit_page_details";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:indexName,
        _id:mId
      });
      return this.http.post(url, postBody,).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

//EDIT BT
  getEditData(mId,mData,indexName) {
    
    let url = this.baseApiUrl + "edit_data";
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:indexName,
        _id:mId,
        data:mData
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
