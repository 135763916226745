import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  public getToken() {
    let token = null;
    const tokenStr = sessionStorage.getItem("token");
    if (tokenStr) {
      token = tokenStr;
    }
    return token;
  }

  //Get all users
  getAdminUsersList() {
    let url = this.baseApiUrl + "get_all_user";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
    });
    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  //edit users
  adminUpdateUser(jsonData) {
    let url = this.baseApiUrl + "update_user";

    let postBody = JSON.stringify(jsonData);
    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  //add users
  adminAddUser(jsonData) {
    let url = this.baseApiUrl + "add_user";

    let postBody = JSON.stringify(jsonData);

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  //add users 35.170.68.215:8082/api/disable_user
  disableUser(userId) {
    let url = this.baseApiUrl + "disable_user";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      disable_user_id: userId
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  enableUser(userId) {
    let url = this.baseApiUrl + "enable_user";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      enable_user_id: userId
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  //Get all users
  getViewListList() {
    let url = this.baseApiUrl + "reference_management_details";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
    });
    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  

    //Get all users
    getServicesList() {
      let url = this.baseApiUrl + "get_services";
  
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
    }

    getStartServices(serviceName){
      let url = this.baseApiUrl + "start_services";
  
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        service_name: serviceName,
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          
          return response;
        })
      );
    }

    //Add new 
  getRefAddData(mData) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "add_data";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:"reference_master",
        data:mData
      });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  //35.170.68.215:8082/api/edit_data
  referenceEditData(data, id) {
    let url = this.baseApiUrl + "edit_data";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      index_name: "reference_master",
      _id: id,
      data: data
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }


    //Get all users
    getSearchUsersList(searchKey) {
      let url = this.baseApiUrl + "user_admin_section";
  
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        search_key:searchKey
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
    }
  
}
