import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CommonService } from '../../shared/services/commonService.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseApiUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private commonService: CommonService) { }

  getUserImageDetails() {

    let url = this.baseApiUrl + "get_image";

    let token = this.commonService.getToken();

    // if (token) {
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName
      });

      // const headers = new HttpHeaders({
      //   Authorization: `Bearer ${token}`,
      //   "Content-Type": "application/json",
      // });

      return this.http.post(url, postBody, { 
        // headers: headers , 
        responseType: 'blob' }).pipe(
        map((response: any) => {
          return response;
        })
      );
    // }
  }

  uploadProfileImg(formData) {
    let token = this.commonService.getToken();

    if (token) {
      let url = this.baseApiUrl + "upload_image";

      let headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });

      return this.http.post(url, formData,
         { headers: headers }
         ).pipe(
        map((response) => {
          return response;
        })
      );
    }
  }

  removeProfileImg(){
    let token = this.commonService.getToken();

    // if (token) {
      let url = this.baseApiUrl + "delete_image";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName
      });

      // let headers = new HttpHeaders({
      //   Authorization: `Bearer ${token}`,
      //   "Content-Type": "application/json",
      // });

      return this.http.post(url, postBody,
        //  { headers: headers }
         ).pipe(
        map((response) => {
          return response;
        })
      );
    // }
  }
}
