import { TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loaderService';
import { ReportService } from 'src/app/shared/services/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  @ViewChild("editReport", { static: true }) public editReport: TemplateRef<any>;
  @ViewChild("addReport", { static: true }) public addReport: TemplateRef<any>;
  item: any = 0;
  selectedItem:any=0;
  allUserMessage: Array<any>=[{"base_dn":"NULL","delegation":"NO","delegation_by":"NULL","delegation_end":"NULL","delegation_start":"NULL","disable":"NO","email":"tina_lusiana@test.com","first_name":"Admin1","id":1,"last_name":"User","password":"tsel@123","phone":"11111111213","profile":"All","role":"Admin","section":"All","user_name":"admin"},{"base_dn":"NULL","delegation":"NO","delegation_by":"NULL","delegation_end":"NULL","delegation_start":"NULL","disable":"NO","email":"test@user.com","first_name":"Test","id":2,"last_name":"User","password":"test","phone":"8271234219","profile":"All","role":"Admin","section":"All","user_name":"test"},{"base_dn":"NULL","delegation":"NO","delegation_by":"NULL","delegation_end":"NULL","delegation_start":"NULL","disable":"NO","email":"demo@demo.com","first_name":"Demo","id":3,"last_name":"DEMO","password":"Demo_123","phone":"1111111111","profile":"ReadOnly","role":"Admin","section":"All","user_name":"demo@demo.com"},{"base_dn":"NULL","delegation":"NO","delegation_by":"NULL","delegation_end":"NULL","delegation_start":"NULL","disable":"NO","email":"neha@test.com","first_name":"Neha1","id":4,"last_name":"neha","password":"Neha_123","phone":"1111111111","profile":"All","role":"Admin","section":"All","user_name":"neha@test.com"},{"base_dn":"NULL","delegation":"NO","delegation_by":"NULL","delegation_end":"NULL","delegation_start":"NULL","disable":"NO","email":"ashish@test.com","first_name":"Ashish1","id":5,"last_name":"ashish1","password":"Ashish_123","phone":"1111111111","profile":"ReadOnly","role":"Admin","section":"Dashboard","user_name":"ashish@test.com"}];
  userObjet: any;
  relatedTablesList: Array<any> = [];
  editReportForm: FormGroup;
  relatedTablesListResult: Array<any> = [];
  addReportForm: FormGroup;
  contentRportResult:Array<any>;
  rportEditDetailsResult:Array<any>;
 // rportDetailsResult:Array<any>;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;

  //Add report model
  relatedTableDropdownSettings: any = {};
  intendedReceipientsDropdownSettings: any = {};
  relatedTableSelectedItems: Array<any> = [];
  addPageDetailsMessage: Array<any> = [];
  reportDomainList:Array<any>= [];
  relatedTableList: Array<any> = [];
  relatedTablesColumnData: Array<any> = [];
  businessOwnerList: Array<any> = [];
  dataStewardList: Array<any> = [];
  reportingAnalyticsStewardList: Array<any> = [];
  businessTermDomain: Array<any> = [];
  termTaxonomyCategory: Array<any> = [];
  termTaxonomyTermType: Array<any> = [];
  termTaxonomySubCategory: Array<any> = [];
  addSelectedRelatedTables: any = [];
  data_dictionary_id_val:Array<any> = [];
  dimensionArray:Array<any> = [];
  measurementArray:Array<any> = [];
  domainAddVal: any;
  domainEditVal: any;
  linkUrl:any;

  selectedRelatedTableColumns:any = [];
  selectedAddRTColumns:any = [];

  //modal
  nextClicked: boolean = false;
  submitted: boolean = false;
  submitted1: boolean = false;
  submittedEdit: boolean = false;

  // 2nd form on Click of Next
  relatedTableColumnsForm: FormGroup;
  selectedintendedReceipientsColumns: any = [];
  intendedReceipientsColumnsAdd: any = [];
  groupData: any;

  DateTimeEnd = '20160313T00:00:00';
  Date = '20160313T00:00:00';
  CurrentDate = new Date();
  user: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private reportService: ReportService,
    public modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.currentPage = 1;
    this.totalItems = 0;
    this.itemsPerPage = 20;
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.getReportAddPageDetails()
    //added for first call 
    setTimeout(() => {
      this.getReportData("report_revenue");
    }, 1000);
    this.initializeReportEditForm();
    this.initializeReportAddForm();

    this.relatedTableDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'table_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.intendedReceipientsDropdownSettings = {
      singleSelection: false,
      idField: 'item_text',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    
   }

   //select item from list
   onSelected(indexname, index) {
    this.contentRportResult=[];
    this.item = index;
    this.getReportData(indexname);
  }

  onSelectedItem(message,index1){
    this.selectedItem = index1;
    this.userObjet = this.contentRportResult[index1];
    this.dimensionArray=this.contentRportResult[index1].data.dimensions.split(/[,\n]+/);
    this.measurementArray=this.contentRportResult[index1].data.measurement.split(/[,\n]+/);
    
    this.linkUrl=this.userObjet["link"];
    this.setValuesIEditForm(this.contentRportResult[index1]);
    this.getDeatilsReportData(this.contentRportResult[index1].id,'report_'+this.contentRportResult[index1].data.Domain.toLowerCase());
  }
 

//Edit Report modal
  editReportFun(_id,domain){
    //call edit details.
    this.getEditDeatilsReportData(_id,'report_'+domain.toLowerCase());
    this.reportEditNgModal();
  }
  
  reportEditNgModal() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalService.open(this.editReport, ngbModalOptions);
  }
   // convenience getter for easy access to form fields
   get formControlsEdit() { return this.editReportForm.controls; }

   initializeReportEditForm() {
    const UrlsReg = '(http(s)?://)?([\\da-z.-]+)[/\\w .-]*/?';
    this.editReportForm = this.formBuilder.group({
      Domain: ['', Validators.required],
      report_title: ['', Validators.required],
      description: ['', []],
      dimensions: ['', []],
      measurement: ['', []],
      frequency: ['', []],
      granularity: ['', []],
      channel: ['', []],
      channel_detail: ['', []],
      link: ['', []],
      reporting_steward: ['', []],
      intended_receipients: [this.selectedRelatedTableColumns, []],
      data_dictionary_id:['', []]
    });
  }

 //Add Report modal
  addReportFun(){
    this.addSelectedRelatedTables = [];
    this.intendedReceipientsColumnsAdd = [];
    this.reportAdd()
  }
  
  reportAdd() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalService.open(this.addReport, ngbModalOptions);
    this.addReportForm.reset();
    this.initializeReportAddForm()
  }

  // convenience getter for easy access to form fields
  get formControlsAdd() { return this.addReportForm.controls; }

  initializeReportAddForm() {
    
    const UrlsReg1 = '(http(s)?://)?([\\da-z.-]+)[/\\w .-]*/?';
    this.addReportForm = this.formBuilder.group({
      Domain: ['', Validators.required],
      report_title: ['', Validators.required],
      description: ['', []],
      dimensions: ['', []],
      measurement: ['', []],
      frequency: ['', []],
      granularity: ['', []],
      channel: ['', []],
      channel_detail: ['', []],
      link: ['',[]],
      reporting_steward: ['', []],
      intended_receipients: [this.intendedReceipientsColumnsAdd, []],
      data_dictionary_id:[this.relatedTableSelectedItems, []]
    });
  }
  

  initRelatedTableColumnsArray() {
    this.relatedTableColumnsForm = this.formBuilder.group({
      relatedTables: this.formBuilder.array([
        this.formBuilder.group({
          relatedTablesColumns: [this.selectedRelatedTableColumns, [Validators.required]],
        })

      ])
    });
  }

  newRelatedTableColumnsForm() {
    return this.formBuilder.group({
      relatedTablesColumns: [[], [Validators.required]],
    });
  }

  addNewRelatedTablesColumn() {
    this.selectedRelatedTableColumns = [];
    this.groupData = this.relatedTableColumnsForm.get('relatedTables');
    this.groupData.controls.push(this.newRelatedTableColumnsForm());
  }

  get relatedTables() {
    return <any>this.relatedTableColumnsForm.get('relatedTables');
  }

  onSaveSubmit() {

  }

   //Get list Reports
   getReportData(index_name) {
     
    this.loaderService.display(true);
    this.reportService.getReportList(index_name,this.currentPage, this.itemsPerPage)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.contentRportResult = data["Message"];
        
            this.userObjet=this.contentRportResult[0];
            this.dimensionArray=this.contentRportResult[0].data.dimensions.split(/[,\n]+/);
            this.measurementArray=this.contentRportResult[0].data.measurement.split(/[,\n]+/);
            this.getDeatilsReportData(this.userObjet.id,index_name)
            this.setValuesIEditForm(this.userObjet);
            
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  //pagination code 
  pageChanged(event: any): void {
    this.currentPage = event;

    this.reportService.getReportList('report_revenue',this.currentPage, this.itemsPerPage).subscribe(
        (data) => {
          if (data["Success"] > 0) {
            
            this.contentRportResult = data["Message"];
            
            this.contentRportResult = this.contentRportResult.slice(0, 50);
            this.userObjet=this.contentRportResult[0];
            this.getDeatilsReportData(this.userObjet.id,'report_revenue')
            this.setValuesIEditForm(this.userObjet);
            this.totalItems = data["Total_Count"];
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
        }
      );
  }

  onNextSubmit() {
    this.submitted = true;
    let data_dictionary_id_updated = [];
     if (this.addReportForm.invalid) {
      return;
    }

    this.domainAddVal=this.addReportForm.get('Domain').value
    
     this.data_dictionary_id_val=this.addReportForm.get('data_dictionary_id').value
    // modifiled array for data_dictionary_id as list of IDs
    for (var val of this.addReportForm.get('data_dictionary_id').value) {
      data_dictionary_id_updated.push(val['id']);
    }
    this.addReportForm.controls.data_dictionary_id.setValue(data_dictionary_id_updated);
    this.getReportAddData('report_'+this.domainAddVal.toLowerCase(),this.addReportForm.value)
  }

  //API call for edit filled
  getReportAddData(indexName,data) {

    this.loaderService.display(true);
    this.modalService.dismissAll();
    this.reportService.getReportAddData(indexName,data).subscribe(
        (data) => {
          if (data["Success"] > 0) {
           
            this.getReportData(indexName);
            this.toasterService.success(data["Message"]);
            this.initializeReportAddForm()
            this.submitted=false;
            
          } else {
            this.toasterService.error(data["Message"]);
            // this.initializeReportAddForm()
            // this.submitted=false;
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  //API call for Business Onwer,Data Steward and others fields
  getReportAddPageDetails() {
    this.loaderService.display(true);
    this.reportService.getReportAddPageDetails().subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.addPageDetailsMessage = data["Message"];
          this.businessOwnerList = this.addPageDetailsMessage["business_owner"];
          this.dataStewardList = this.addPageDetailsMessage["data_steward"];
          this.businessTermDomain = this.addPageDetailsMessage['group_domain'];
          this.termTaxonomyCategory = this.addPageDetailsMessage["term_taxonomy_category"];
          this.termTaxonomySubCategory = this.addPageDetailsMessage["term_taxonomy_sub_category"];
          this.termTaxonomyTermType = this.addPageDetailsMessage["term_taxonomy_term_type"];
          this.reportingAnalyticsStewardList = this.addPageDetailsMessage['reporting_metadata_steward'];
          this.relatedTableList = this.addPageDetailsMessage["related_table"];
          this.reportDomainList = this.addPageDetailsMessage["report_domain"];
          
        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }
  goToLink(url: string){
    window.open(url, "_blank");
  }

  onEditSubmit() {
    this.submittedEdit = true;
    let data_dictionary_id_updated = [];
     if (this.editReportForm.invalid) {
      return;
    }

    this.domainEditVal=this.editReportForm.get('Domain').value

    // modifiled array for data_dictionary_id as list of IDs
    for (var val of this.editReportForm.get('data_dictionary_id').value) {
      data_dictionary_id_updated.push(val['id']);
    }
    this.editReportForm.controls.data_dictionary_id.setValue(data_dictionary_id_updated);
    

    this.getReportDataEdit('report_'+this.domainEditVal.toLowerCase(),this.userObjet.id,this.editReportForm.value)
  }

    //Get list Reports
    getEditDeatilsReportData(mId,indexName) {
      this.loaderService.display(true);
      this.reportService.getReportEditPageDetails(mId,indexName)
        .subscribe(
          (data) => {
            if (data["Success"] > 0) {
              this.rportEditDetailsResult = data["Message"];
               
               this.reportDomainList = this.rportEditDetailsResult["domain"];
               this.businessOwnerList = this.rportEditDetailsResult["reporting_steward"];

              this.relatedTableSelectedItems = [];
              this.relatedTableList.forEach(element => {
              if (this.rportEditDetailsResult['selected_table_id'] != null) {
              this.rportEditDetailsResult['selected_table_id'].forEach(id => {
                if (element.id === id) {
                  this.relatedTableSelectedItems.push(element);
               }
              })
            }
            else {
              this.relatedTableSelectedItems = [];
           };
          });
            } else {
              this.toasterService.error(data["Message"]);
            }
            this.loaderService.display(false);
          },
          (error) => {
            this.toasterService.error("Something went wrong!");
          }
        );
    }

     //Get list Reports
     getDeatilsReportData(mId,index_name) {
      this.loaderService.display(true);
      this.reportService.getReportsDetailsData(mId,index_name)
        .subscribe(
          (data) => {
            if (data['Success'] > 0) {
              this.relatedTablesListResult = data['Related_Tables_Data'];
            } else {
              this.toasterService.error(data['Message']);
            }
            this.loaderService.display(false);
          },
          (error) => {
            this.toasterService.error("Something went wrong!");
          }
        );
    }

     //Set values to deatils form
  setValuesIEditForm(userObjectMessage) {
    
    this.editReportForm.controls.Domain.setValue(userObjectMessage.data.Domain);
    this.editReportForm.controls.report_title.setValue(userObjectMessage.data.report_title);
    this.editReportForm.controls.description.setValue(userObjectMessage.data.description);
    this.editReportForm.controls.dimensions.setValue(userObjectMessage.data.dimensions);
    this.editReportForm.controls.measurement.setValue(userObjectMessage.data.measurement);
    this.editReportForm.controls.frequency.setValue(userObjectMessage.data.frequency);
    this.editReportForm.controls.granularity.setValue(userObjectMessage.data.granularity);
    this.editReportForm.controls.channel.setValue(userObjectMessage.data.channel);
    this.editReportForm.controls.channel_detail.setValue(userObjectMessage.data.channel_detail);
    this.editReportForm.controls.link.setValue(userObjectMessage.data.link);
    this.editReportForm.controls.reporting_steward.setValue(this.businessOwnerList[this.businessOwnerList.indexOf(userObjectMessage.data.reporting_steward)]);
    this.editReportForm.controls.data_dictionary_id.setValue(this.relatedTableSelectedItems);
    this.selectedintendedReceipientsColumns=userObjectMessage.data.intended_receipients;
    this.editReportForm.controls.intended_receipients.setValue(this.selectedintendedReceipientsColumns);
  }

  getReportDataEdit(indexEdit,mId,mData) {
    this.loaderService.display(true);
    this.reportService.getReportEditData(indexEdit,mId,mData)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.modalService.dismissAll();
            this.getReportData(indexEdit);
             this.toasterService.success(data["Message"]);
             this.initializeReportEditForm()
             this.submittedEdit=false;
             
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  viewDataDicDetails(tableId){
    this.router.navigate(["user/dataDictionary/details", tableId]);
  }
 
}


