import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loaderService';
import { CommonService } from 'src/app/shared/services/commonService.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-notification-module',
  templateUrl: './notification-module.component.html',
  styleUrls: ['./notification-module.component.scss']
})
export class NotificationModuleComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  notificationList: Array<any>;
  displayedColumns: string[];
  refItem: any;
  referenceName: string;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  contentResult: Array<any> = [];
  comparisonMode: boolean = false;


  constructor(
    private router: Router,
    public loaderService: LoaderService,
    public commonService: CommonService,
    private toasterService: ToastrService,
    private notificationService: NotificationService,
    ) { }

  ngOnInit() {
    this.currentPage = 1;
    this.totalItems = 0;
    this.itemsPerPage = 50;
    this.referenceName = '';
    this.notificationList = [];
    this.displayedColumns = [];
    this.getNotificationDetails();
  }

  showNotificationDetails(event){
    if(event)
      this.comparisonMode = false;
    else
      this.comparisonMode = true;
  }

  getNotificationDetails() {
    this.loaderService.display(true);
    this.notificationService.getNotificationList().subscribe(
      (data) => {

        if (data["Success"] > 0) {
          this.notificationList = data['Message']
        } else {
          this.toasterService.error('No more Files available for Approval');
          this.notificationList = [];
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        this.toasterService.error('Something went wrong!')
      }
    );
  }

  viewDetails(item) {
    this.comparisonMode = true;
    this.refItem = item;
    this.referenceName = item.reference_name;
    this.viewUpdatedRef(this.refItem.summary_file_name);
  }

  viewUpdatedRef(summaryFileName) {
    this.loaderService.display(true);
    this.commonService.getComparision(summaryFileName, this.currentPage, this.itemsPerPage).subscribe(data => {
      // this.resultDetails = data;
      if (data["Success"] > 0) {
        this.displayedColumns = data['Message']['header']; 

        // find index of Stauts column
        var indexOfStatusCol = this.displayedColumns.indexOf('Status');

        // remove it from Table headers array
        this.displayedColumns.splice(indexOfStatusCol, 1);

        // Add back Status column at first index
        this.displayedColumns.unshift('Status');

        this.contentResult = data['Message']['data'];
        this.totalItems = data['Message']['total_count'];

      } else {
        this.toasterService.error(data['Message']);
      }
      this.loaderService.display(false);
    },
      (error) => {
        this.loaderService.display(false);
      })
  }

  pageChanged(event: any): void {

    this.loaderService.display(true);

        this.currentPage = event;
        // get list again 
        this.commonService.getComparision(this.refItem.summary_file_name, this.currentPage, this.itemsPerPage).subscribe(data => {
          // this.resultDetails = data;
          if (data["Success"] > 0) {

            this.contentResult = data['Message']['data'];
            this.totalItems = data['Message']['total_count'];

          } else {
            this.toasterService.error(data['Message']);
          }
          this.loaderService.display(false);
        },
          (error) => {
            this.loaderService.display(false);
          })
  }

  acceptUpdate() {
    this.blockUI.start('Updating List...Do not press Refresh or Go back !!');
    this.commonService.approveChanges(this.refItem.uploaded_file_name, this.refItem.summary_file_name).subscribe(data => {
      if (data["Success"] > 0) {
        this.toasterService.success(data['Message']);
        this.blockUI.stop();
        this.getNotificationDetails();
        this.comparisonMode = false;
      } else {
        this.blockUI.stop();
        this.toasterService.error(data['Message']);
      }
    },
      error => {
        this.blockUI.stop();
        this.toasterService.error('Something went wrong!');
      })
  }

  declineUpdate() {
    this.commonService.declineChanges(this.refItem.uploaded_file_name, this.refItem.summary_file_name).subscribe(data => {
      if (data["Success"] > 0) {
        this.toasterService.success(data['Message']);
        this.getNotificationDetails();
        this.comparisonMode = false;
      } else {
        this.toasterService.error(data['Message']);
      }
    },
      error => {
        this.toasterService.error('Something went wrong!');
      })
  }


}
