import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { log } from 'console';

@Injectable({
  providedIn: "root",
})
export class BusinessTermService {
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  public getToken() {
    let token = null;
    const tokenStr = sessionStorage.getItem("token");
    if (tokenStr) {
      token = tokenStr;
    }
    return token;
  }

  getBusinessTermList(term, pageNo, pageSize) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "index_data_list";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name: term,
        page_no: pageNo,
        page_Size: pageSize
      });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

 //Detail BT
  getBusinessTermsDetailsData(mId) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "global_search_details";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:"business_glossaries",
        _id:mId
      });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
          
        })
      );
  }

 //EDIT Deatils BT
 getBusinessTermsEditPageDetails(mId) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "edit_page_details";
    
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:"business_glossaries",
        _id:mId
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

//EDIT BT
  getBusinessTermsEditData(mId,mData) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "edit_data";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:"business_glossaries",
        _id:mId,
        data:mData
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  getRelatedTableColumnsListForAdd(relatedTables){
    let url = this.baseApiUrl + "next_page_details";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:"business_glossaries",
        related_tables_id:relatedTables,
      });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
         
          
          return response;
        })
      );
  }

  getRelatedTableColumnsList(relatedTables, tableID){
    let url = this.baseApiUrl + "next_page_details";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:"business_glossaries",
        related_tables_id:relatedTables,
        _id: tableID
      });
      
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          
          return response;
        })
      );
  }

//Add new 
  getBusinessTermsAddData(mData) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "add_data";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:"business_glossaries",
        data:mData
      });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  //add Deatils BT
  getBusinessTermsAddPageDetails() {
    
    let url = this.baseApiUrl + "add_page_details";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
      });

      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

}
