import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SessionStorageService } from './sessionStorage';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './loginService';


@Injectable({
  providedIn: 'root'
})
export class HttpIntersepterService implements HttpInterceptor {

  constructor(private router: Router,
              private sessionStorageService: SessionStorageService,
              private loginService: LoginService,
              private toasterService: ToastrService) {
  }


  //function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    //how to update the request Parameters
    //logging the updated Parameters to browser's console

    let token = this.sessionStorageService.getToken();
    

    // To ignore request which can't post via Content-type: application-json
    let ignore = typeof request.body === "undefined" || request.body === null || request.body.toString() === "[object FormData]" || request.headers.has("Content-Type");

    if (ignore) {
      return next.handle(request);
    }

    else if (token) {
      const authReq = request.clone({
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        })
      });

      return next.handle(authReq).pipe(
        tap(
          event => {

            //logging the http response to browser's console in case of a success
            if (event instanceof HttpResponse) {

            }
          },
          error => {
            //logging the http response to browser's console in case of a failuer
            if (error.status === 401) {
              this.toasterService.error('Another User Logged already logged in.')
              sessionStorage.clear();
              this.loginService.isUserLoggedIn = false;
              this.router.navigate(['/login']);

            }
          }
        ));

    }
    // else {
    //   return next.handle(request).pipe(
    //     tap(
    //       event => {

    //         //logging the http response to browser's console in case of a success
    //         if (event instanceof HttpResponse) {

    //         }
    //       },
    //       error => {
    //         //logging the http response to browser's console in case of a failuer
    //         if (error.status === 401) {

    //           alert('Invalid Details');
    //           sessionStorage.clear();
    //           this.router.navigate(['/login']);

    //         }
    //       }
    //     )
    //   );
    // }
  }
}
