import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
@Injectable()
export class FormValidationService {
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        let config = {
            'invalidCreditCard': 'Is invalid credit card number',
            'required': 'Please fill the mandatory fields',
            
            'invalidEmailAddress': 'The username is invalid. Please try again',
            'invalidPassword': 'Password should be between 6 and 20 characters',
            'invalidDate': 'Invalid Date format',
            'minlength': `Minimum length ${validatorValue.requiredLength}`,
            'maxlength': `Maximum length ${validatorValue.requiredLength}`,
            'invalidPhoneNo': `Please Enter Valid Phone Number`,
            'notMatchedpassword': `Passwords are not matched`,
            'invalidNo': `Please Enter Numbers only `,
            'areEqual': `Passwords not matched `,
            'invalidUrl': `Please Enter Valid URL`,
            'invalidName':`Field should be Alphabets only`,
            'invalidRoleName':`Role name should start with ROLE_,should have only alphanumeric characters and underscore`,
        };

        return config[validatorName];
    }

    static creditCardValidator(control) {
        // Visa, MasterCard, American Express, Diners Club, Discover, JCB

        if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
            return null;
        } else {
            return { 'invalidCreditCard': true };
        }
    }

    static urlValidator(control){
       if(control.value){
        if (control.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
            return null;
        } else {
            return { 'invalidUrl': true };
        }

        }
       
    }
    static nameValidator(control){
        
        if(control.value){
            if(control.value.match(/^(?=.*?[a-zA-Z])[a-zA-Z ]*$/)){
            return null;
        }else{
            return {'invalidName':true};
        }    
        }
        
    }

    static MatchPassword(AC: AbstractControl) {
       let password = AC.get('oldpwd').value; // to get value in input tag
       let confirmPassword = AC.get('cpwd').value; // to get value in input tag
        if(password != confirmPassword) {
                         AC.get('cpwd').setErrors( {MatchPassword: true} )
            
        } else {
            
            return null
        }
    }

    static emailValidator(control) {
        // RFC 2822 compliant regex
        
        if(control.value != '' || control.value == ' '){

          if( control.value != null)  {   
        if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return null;
        } else {
            return { 'invalidEmailAddress': true };
        }

        }

        }
    }
    static areEqual(control) {
      
    }
    static numberValidator(control) {
        
        if(control.value.match(/^[0-9]*$/))
            return null;
        else
             return { 'invalidNo': true };
      
    }

    static dateValidator(control){
        let datePattern = /^\d{4}\-\d{1,2}\-\d{1,2}$/;
         if (control.value.match(datePattern)){
            return null;
         }else{
            return { 'invalidDate': true };
         }
    }


    static passwordValidator(control) {
        // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        let pattern = /^.*(?=.{6,20})(?=.*[a-zA-Z0-9]).*$/;
        if (control.value.match(pattern)) {
        
            return null;
        } else {
            return { 'invalidPassword': true };
        }
    }
    static roleNameValidator(control){
        if(control.value){
            if(control.value.match(/ROLE_(.[A-Za-z0-9_]*)/)){
            return null;
        }else{
            return {'invalidRoleName':true};
        }    
        }
    }

    static requireCheckboxesToBeCheckedValidator(control): ValidatorFn {
        let minRequired=1
        return function validate (formGroup: FormGroup) {
          let checked = 0;
      
          Object.keys(formGroup.controls).forEach(key => {
            const control = formGroup.controls[key];
      
            if (control.value === true) {
      
              checked ++;
            }
          });
          
          if (checked < minRequired) {
      
            return {
              requireOneCheckboxToBeChecked: true,
            };
          }
      
          return {requireOneCheckboxToBeChecked: false};
        };
      }
}
