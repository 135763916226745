import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(
    private http: HttpClient,
    private toasterService: ToastrService
  ) { }

  public getToken() {
    let token = null;
    const tokenStr = sessionStorage.getItem("token");
    if (tokenStr) {
      token = tokenStr;
    }
    return token;
  }

  getReferenceSearchResults(searchText) {
    let url = this.baseApiUrl + "SearchData";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      text: searchText,
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getReferenceDetailSearchResults(searchText, refName, pageNo, pageSize) {
    let url = this.baseApiUrl + "reference_search";

    let token = this.getToken();

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      keyword: searchText,
      view_file: refName,
      page_no: pageNo,
      page_Size: pageSize
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }



  //Search any term API 
  getGlobalReferenceSearchResults(indexToSearch, searchText) {
    let url = this.baseApiUrl + "global_search ";
    let token = this.getToken();
    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      index_name: indexToSearch,
      search_key: searchText,
    });
    return this.http.post(url, postBody
    ).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  
  getReferenceResultDetails(id, pageNo, pageSize,historyFileSequence) {
    let url = this.baseApiUrl + "ViewData";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      view_file: id,
      page_no: pageNo,
      page_Size: pageSize,
      history_file_sequence:historyFileSequence,
    });
     
    return this.http.post(url, postBody,
    ).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  getRefResultUploadDetails(id) {

    let url = this.baseApiUrl + "FileInfo";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      view_file: id,
    });

    return this.http.post(url, postBody,
    ).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

  validateFile(file) {
    var maxSize = 1024000000;
    let valid: boolean = false;
    let fileName = file.name;

    var allowedFiles = [".csv", ".txt", ".xls", ".xlsx"];

    var fileExtn: string =
      fileName.substring(
        fileName.lastIndexOf(".") + 1,
        fileName.length
      ) || fileName;

    fileExtn = ".".concat(fileExtn);

    if (allowedFiles.indexOf(fileExtn) >= 0) {

      if (file.size <= 0) {
        this.toasterService.error("zeroByteFileError");
        valid = false;
      } else if (file.size > maxSize) {
        this.toasterService.error("fileSizeError");
        valid = false;
      } else {
        valid = true;
      }
    } else {
      this.toasterService.error(fileExtn + " is an invalid format for upload");
      valid = false;
    }
    return valid;
  }

  validateImgFile(file) {
    var maxSize = 5000000;
    let valid: boolean = false;
    let imgFileName = file.name;

    var allowedFiles = [".png", ".jpeg", ".jpg"];

    var imgExtn: string =
      imgFileName.substring(
        imgFileName.lastIndexOf(".") + 1,
        imgFileName.length
      ) || imgFileName;

    imgExtn = ".".concat(imgExtn);

    if (allowedFiles.indexOf(imgExtn) >= 0) {

      if (file.size <= 0) {
        this.toasterService.error("zeroByteFileError");
        valid = false;
      } else if (file.size > maxSize) {
        this.toasterService.error("fileSizeError");
        valid = false;
      } else {
        valid = true;
      }
    } else {
      this.toasterService.error(imgExtn + " is an invalid format for upload");
      valid = false;
    }
    return valid;
  }

  uploadCSV(formData) {
    let token = this.getToken();

    // if (token) {
    let url = this.baseApiUrl + "upload";
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.post(url, formData,
      {
        headers: headers,
        reportProgress: true,
        observe: 'events'
      }).pipe(
        map((response) => {
          return response;
        })
      );
    // }
  }

  uploadNewRefFile(formData,elasticId) {
    let token = this.getToken();

    // if (token) {
    let url = this.baseApiUrl + "add_reference_file";
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    let postBody = {
      file:formData,
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      _id:elasticId
    };
    ///console.log(postBody);
    return this.http.post(url,formData,{
      headers: headers,
      reportProgress: true,
      observe: 'events',

    }).pipe(
        map((response) => {
          return response;
        })
      );
    // }
  }

  getComparision(summaryFile, pageNo, pageSize) {

    let url = this.baseApiUrl + "ViewSummary";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      view_file: summaryFile,
      page_no: pageNo,
      page_Size: pageSize
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );

  }

  approveChanges(uploadedFile, summaryFile) {

    let url = this.baseApiUrl + "approved";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      uploaded_file: uploadedFile,
      summary_file: summaryFile
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );

  }

  sendForApprovalApi(uploadedFile, summaryFile) {
    let url = this.baseApiUrl + "SendForApproval";
    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      uploaded_file: uploadedFile,
      summary_file: summaryFile
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );

  }

  declineChanges(uploadedFile, summaryFile) {

    let url = this.baseApiUrl + "cancel";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      uploaded_file: uploadedFile,
      summary_file: summaryFile
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );

  }

  downloadServices(refName) {

    let url = this.baseApiUrl + "Download";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      reference_name: refName,
    });

    return this.http.post(url, postBody,
      {
        responseType: "blob"
      }).pipe(
        map((response) => {
          return response;
        })
      );
  }

  downloadTemplate(fileName) {

    let url = this.baseApiUrl + "Template";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      file_name: fileName,
    });
  
    return this.http.post(url, postBody, {
      responseType: "blob"
    }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getUserLoginCount() {
    let url = this.baseApiUrl + "user_login_count";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName
    });

    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
  }

}
