import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'valueAnalysisDp'
})
export class ValueAnalysisDpPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      if (item.value_pattern != null) {
        return item.value_pattern.toLowerCase().includes(searchText);
      }
    });
  }
}