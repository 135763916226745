import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
      
    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    
    return items.filter(item => {
      if(item.first_name != null || item.last_name != null){
        return item.first_name.concat(item.last_name).toLowerCase().includes(searchText);
      }
    });
  }
}