import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataLineageService {
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  public getToken() {
    let token = null;
    const tokenStr = sessionStorage.getItem("token");
    if (tokenStr) {
      token = tokenStr;
    }
    return token;
  }

 //Detail Data Dictionary
  getDataLineageDetailsData(mId) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "data_lineage_details";
      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        data_dict_id:mId
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          
          return response;
        })
      );
  }

  
  

}
