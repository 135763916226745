import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreventLoggedInAccess } from './guards/preventLoggedInAccess.guard';
import { LoginComponent } from './login/login.component';
import { DefaultComponent } from './layouts/default/default.component';
import { HomeComponent } from './modules/home/home.component';
import { SearchResultComponent } from './modules/home/search-result/search-result.component';
import { SearchResultDetailsComponent } from './modules/home/search-result/search-result-details/search-result-details.component';
import { ReferenceComponent } from './modules/reference/reference.component';
import { RefResultDetailsComponent } from './modules/reference/reference-result-details/ref-result-details.component';
import { BusinessTermComponent } from './modules/business-term/business-term.component';
import { BusinessTermDetailComponent } from './modules/business-term/business-term-detail/business-term-detail.component';
import { DataDictionaryComponent } from './modules/data-dictionary/data-dictionary.component';
import { DataDictionaryDetailComponent } from './modules/data-dictionary/data-dictionary-detail/data-dictionary-detail.component';
import { AdminPageComponent } from './modules/admin-page/admin-page.component';
import { DataLineageDetailsComponent } from './modules/data-lineage/data-lineage-details/data-lineage-details.component';
import { DataProfilingQualityComponent } from './modules/data-profiling-quality/data-profiling-quality.component';
import { DataProfilingQualityDetailsComponent } from './modules/data-profiling-quality/data-profiling-quality-details/data-profiling-quality-details.component';
import { DataProfilingComponent } from './modules/data-profiling/data-profiling.component';
import { DataProfilingDetailsComponent } from './modules/data-profiling/data-profiling-details/data-profiling-details.component';
import { NotificationModuleComponent } from './modules/notification-module/notification-module.component';
import { ReportComponent } from './modules/report/report.component';


const routes: Routes = [
  {
    path: 'user',
    component: DefaultComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'searchResults',
        component: SearchResultComponent
      },
      {
        path: 'searchResults/details/:id',
        component: SearchResultDetailsComponent
      },
      {
        path: 'business',
        component: BusinessTermComponent,
      },
      {
        path: 'business/details/:id',
        component: BusinessTermDetailComponent
      },
      {
        path: 'reference',
        component: ReferenceComponent
      },
      {
        path: 'reference/details/:id',
        component: RefResultDetailsComponent
      },
      {
        path: 'dataDictionary',
        component: DataDictionaryComponent
      },
      {
        path: 'dataDictionary/details/:id',
        component: DataDictionaryDetailComponent
      },
      {
        path: 'admin',
        component: AdminPageComponent
      },
      {
        path: 'dataLineage/details/:id',
        component: DataLineageDetailsComponent
      },
      {
        path: 'dataQuality',
        component: DataProfilingQualityComponent
      },
      {
        path: 'dataQuality/details/:id',
        component: DataProfilingQualityDetailsComponent
      },
      {
        path: 'dataQuality/dataProfiling/:id',
        component: DataProfilingComponent
      },
      {
        path: 'dataQuality/dataProfiling/details/:id',
        component: DataProfilingDetailsComponent
      },
      {
        path: 'notification',
        component: NotificationModuleComponent
      },
      {
        path: 'report',
        component: ReportComponent
      }
    ]
  },
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent, canActivate: [PreventLoggedInAccess] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
