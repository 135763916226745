import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(
    private http: HttpClient
  ) { }

  getNotificationList(){
    let url = this.baseApiUrl + "reference_notification";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName });
      
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }
}
