import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public searchText: string = '';
  public collectionDate: string;
  public refStatus: string;

  constructor() { }
    
  setRefSearchText(searchText: string) {
    this.searchText = searchText;
    return this.searchText;
  }

  getRefSearchText(): string {
    return this.searchText;
  }

  setCollectionDate(date: string) {
    this.collectionDate = date;
    
  }

  getCollectionDate(): string {
    return this.collectionDate;
  }

  getRefStatus(): string {
    return this.refStatus;
  }

  formatDate(dateValue) {
    let curr_date = dateValue.day;
    let curr_month = dateValue.month;
    let curr_year = dateValue.year;
    return (curr_year + "-" + curr_month + "-" + curr_date);

  }
}
