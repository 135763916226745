import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'refFilter'
})
export class ReferencePipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
  
    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    
    return items.filter(item => {

      if(item._source.Reference_Name != null){
        return item._source.Reference_Name.toLowerCase().includes(searchText);
      }
    });
  }
}