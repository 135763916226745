import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BusinessTermService } from "../../shared/services/businessTerm.service";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "../../services/loaderService";
import { SelectAutocompleteComponent } from "mat-select-autocomplete";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from '../../shared/services/commonService.service';
import { DataDictionaryService } from '../../shared/services/dataDictionary.service';

@Component({
  selector: 'app-data-dictionary',
  templateUrl: './data-dictionary.component.html',
  styleUrls: ['./data-dictionary.component.scss']
})
export class DataDictionaryComponent implements OnInit {

  addBTDetailForm: FormGroup;
  submitted = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  data_dictionary_id_updated = [];
  itemsPerPageFrom:number;
  itemsPerPageTo:number;
  totalItemsCount:number;
  dmp_fileName:any="DMP_data_dictionary.xlsx";
  

  //Business term List
  dataDictionaryTerm: FormControl;
  contentResult: Array<any> = [];
  mResponseMessage: Array<any> = [];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;

  relatedTableList: Array<any> = [];
  businessOwnerList: Array<any> = [];
  dataStewardList: Array<any> = [];
  termTaxonomyCategory: Array<any> = [];
  termTaxonomyTermType: Array<any> = [];
  termTaxonomySubCategory: Array<any> = [];
  addPageDeatilsMessage: Array<any> = [];

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private dataDictionaryService: DataDictionaryService,
    public modalService: NgbModal,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.dataDictionaryTerm = new FormControl('');
    this.currentPage = 1;
    this.totalItems = 0;
    this.itemsPerPage = 10;
    this.getDataDictionaryData();
  }

  //Get list Data Dictionary
  getDataDictionaryData() {
    this.loaderService.display(true);
    this.dataDictionaryService.getDataDictionaryList(this.currentPage, this.itemsPerPage)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.contentResult = data["Message"];
            this.contentResult = this.contentResult.slice(0, 50);
            this.totalItems = data["Total_Count"];
            this.totalItemsCount = data["Total_Count"];
            this.itemsPerPageFrom= data["first_page"];
            this.itemsPerPageTo= data["last_page"];
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }


  pageChanged(event: any): void {
    this.currentPage = event;

    this.dataDictionaryService.getDataDictionaryList(this.currentPage, this.itemsPerPage)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.contentResult = data["Message"];

            this.contentResult = this.contentResult.slice(0, 50);
            this.totalItems = data["Total_Count"];
            this.itemsPerPageFrom= data["first_page"]
            this.itemsPerPageTo= data["last_page"]
            
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
        }
      );
  }

  // call the DataDictionaryDetailComponent
  openDetail(id) {
    this.router.navigate(["user/dataDictionary/details", id]);
  }

  searchDataDictionary() {
    if (this.dataDictionaryTerm.value == "") {
      this.itemsPerPage = 10;
      this.getDataDictionaryData();
    }
    else {
      this.loaderService.display(true);

      this.commonService.getGlobalReferenceSearchResults("data_dictionary", this.dataDictionaryTerm.value).subscribe(data => {
        if (data["Success"] > 0) {
          this.contentResult = data['Message'];
          this.totalItems = 0; // to remove pagination from search list
          this.itemsPerPage = this.contentResult.length;
          this.totalItemsCount = data["data_length"];
             this.itemsPerPageFrom= 1;
             this.itemsPerPageTo= data["data_length"];
        } else {
          this.toasterService.error(data['Message']);
        }
        this.loaderService.display(false);
      },
        error => {
          this.toasterService.error("Something went wrong!");
          this.loaderService.display(false);
        })
    }

  }

  downloadTemplateFile() {
    this.loaderService.display(true);

    this.commonService
      .downloadTemplate(this.dmp_fileName)
      .subscribe(
        (data) => {

        let url = window.URL.createObjectURL(data);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = this.dmp_fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        this.loaderService.display(false);
        this.toasterService.success("File downloaded");
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
          this.loaderService.display(true);
        }
      );
  }

}
