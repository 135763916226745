import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import * as Chart from 'chart.js';
import { LoaderService } from 'src/app/services/loaderService';
import { ToastrService } from "ngx-toastr";
import { DataQualityService } from 'src/app/shared/services/dataQuality.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared-service.service';
import { DomSanitizer } from '@angular/platform-browser';

const rgbBlue = '#80DDFF';
const rgbGrey = '#F2F2F2';
const green = '#00CC98';
const amber = '#F7BC28';
const red = '#FF0000';




@Component({
  selector: 'app-data-profiling-quality',
  templateUrl: './data-profiling-quality.component.html',
  styleUrls: ['./data-profiling-quality.component.scss']
})
export class DataProfilingQualityComponent implements OnInit {

  cardHeader: string;
  delegationUserInput: string;
  //for server test
  contentResult: Array<any> = [];

  //for local test 
  //contentResult: Array<any> = [{"accuracy":0,"completeness":1.847,"confirmity":97.999,"date_time":"2020-09-07 04:10:00","table_name":"subs_dim","timely":50.82},{"accuracy":100,"completeness":0,"confirmity":0,"date_time":"2020-09-08 21:00:39","table_name":"bucket_ocs_hh","timely":100}];

  mResponseMessage: Array<any> = [];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;

  pieChartData: any;
  pieChart1: any;
  pieChart2: any;
  pieChart3: any;
  pieChart4: any;

  accuracyTableList: Array<any>;
  conformityTableList: Array<any>;
  completenessTableList: Array<any>;
  timelinessTableList: Array<any>;

  accuracyTableCount: any;
  conformityTableCount: any;
  completenessTableCount: any;
  timelinessTableCount: any;

  itemsPerPageFrom:number;
  itemsPerPageTo:number;
  totalItemsCount:number;


  constructor(private _location: Location,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private dataQualityService: DataQualityService,
    private router: Router,
    private dom:DomSanitizer,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.cardHeader = 'Summary';
    this.accuracyTableList= [];
    this.conformityTableList= [];
    this.completenessTableList= [];
    this.timelinessTableList= [];

    this.delegationUserInput = '';
    this.currentPage = 1;
    this.totalItems = 0;
    this.itemsPerPage = 10;
    this.getDQTableData();
    this.getDataQualityListData();
    
  }

  ngAfterViewInit(){
        // First Pie

        this.pieChart1 = new Chart('pieChart1', {
          type: 'pie',
          data: {
            datasets: [{
              data: [],
              backgroundColor: [
                green,
                amber,
                red
    
              ],
              borderColor: [
                green,
                amber,
                red,
              ],
            }],
            labels: ['100%', '95% - 99%','< 95%']
          },
    
          options: {
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'ACCURACY',
              position: 'top',
              fontColor: '#373737',
              fontFamily: '"HelveticaBold"',
              fontSize: 12,
              padding: 30
            },
            cutoutPercentage: 50,
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                padding: 25,
              }
            },
    
            tooltips: {
              enabled: true,
            }
          },
        });
    
        // Second Pie
    
        this.pieChart2 = new Chart('pieChart2', {
          type: 'pie',
          data: {
            datasets: [{
              data: [],
              backgroundColor: [
                green,
                amber,
                red,
              ],
              borderColor: [
                green,
                amber,
                red,
              ],
            }],
            labels: ['100%', '95% - 99%','< 95%']
          },
    
          options: {
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'CONFORMITY',
              position: 'top',
              fontColor: '#373737',
              fontFamily: '"HelveticaBold"',
              fontSize: 12,
              padding: 30
            },
            cutoutPercentage: 50,
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                padding: 25,
              }
            },
    
            tooltips: {
              enabled: true,
            }
          },
        });
    
        // Third Pie
    
        this.pieChart3 = new Chart('pieChart3', {
          type: 'pie',
          data: {
            datasets: [{
              data: [],
              backgroundColor: [
                green,
                amber,
                red,
    
              ],
              borderColor: [
                green,
                amber,
                red,
              ],
            }],
            labels: ['100%', '95% - 99%','< 95%']
          },
    
          options: {
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'COMPLETENESS',
              position: 'top',
              fontColor: '#373737',
              fontFamily: '"HelveticaBold"',
              fontSize: 12,
              padding: 30
            },
            cutoutPercentage: 50,
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                padding: 25,
              }
            },
    
            tooltips: {
              enabled: true,
            }
          },
        });
    
    
        // Fourth Pie
    
        this.pieChart4 = new Chart('pieChart4', {
          type: 'pie',
          data: {
            datasets: [{
              data: [],
              backgroundColor: [
                green,
                amber,
                red,
    
              ],
              borderColor: [
                green,
                amber,
                red,
              ],
            }],
            labels: ['100%', '95% - 99%','< 95%']
          },
    
          options: {
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'TIMELINESS',
              position: 'top',
              fontColor: '#373737',
              fontFamily: '"HelveticaBold"',
              fontSize: 12,
              padding: 30
            },
            cutoutPercentage: 50,
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                padding: 25,
              }
            },
    
            tooltips: {
              enabled: true,
            }
          },
        });

        this.getPieChartData();
  }

  getDQTableData() {
    this.loaderService.display(true);
    this.dataQualityService.getDQSummaryTableData()
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            
              this.accuracyTableList = data['Message'].Accuracy;
              this.conformityTableList = data['Message'].Conformity;
              this.completenessTableList = data['Message'].Completeness;
              this.timelinessTableList = data['Message'].Timely;

          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        });
  }


  getPieChartData(){
    this.loaderService.display(true);
    this.dataQualityService.getDQSummaryChartData()
      .subscribe(
        (data) => {
          if (data['Success'] > 0) {

            this.pieChartData = data['Message'];
            
            this.pieChart1.data.datasets[0].data.push(this.pieChartData.Accuracy.Green, this.pieChartData.Accuracy.Amber, this.pieChartData.Accuracy.Red);
            // this.pieChart1.data.datasets[0].data = [data['Message'][0].accuracy, 100 - data['Message'][0].accuracy];
            this.pieChart1.update();

            this.pieChart2.data.datasets[0].data.push(this.pieChartData.Conformity.Green, this.pieChartData.Conformity.Amber, this.pieChartData.Conformity.Red);
            // this.pieChart2.data.datasets[0].data = [data['Message'][0].confirmity, 100 - data['Message'][0].confirmity];
            this.pieChart2.update();

            this.pieChart3.data.datasets[0].data.push(this.pieChartData.Completeness.Green, this.pieChartData.Completeness.Amber, this.pieChartData.Completeness.Red);
            // this.pieChart3.data.datasets[0].data = [data['Message'][0].completeness, 100 - data['Message'][0].completeness];
            this.pieChart3.update();

            this.pieChart4.data.datasets[0].data.push(this.pieChartData.Timely.Green, this.pieChartData.Timely.Amber, this.pieChartData.Timely.Red);
            // this.pieChart4.data.datasets[0].data = [data['Message'][0].timely, 100 - data['Message'][0].timely];
            this.pieChart4.update();


          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        });
  }

  redirectBack() {
    this._location.back();
  }

  tabChanged(event){
    if(event.index == 0){
      this.cardHeader = 'Summary';
    }
    else if(event.index == 1){
      this.cardHeader = 'List View';
    }
  }


  openDetail(table_Name, date) {
    
    this.sharedService.setCollectionDate(date);
    this.router.navigate(["user/dataQuality/details/", table_Name]);
  }

  openTest(){
    this.sharedService.setCollectionDate("date");
    this.router.navigate(["user/dataQuality/details/", "table_Name"]);
  }

  //Get list BT
  getDataQualityListData() {
    this.loaderService.display(true);
    this.dataQualityService.getDataQualityList()
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.contentResult = data["Message"];
            this.totalItemsCount = data["data_length"];
           this.itemsPerPageFrom= 1;
           this.itemsPerPageTo= data["data_length"];

          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }
}
