import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceHyphon'
})
export class RemoveHyphonPipe implements PipeTransform{
  transform(value: string, args: string) : string {
    if (value==null) {
        return null;
    }else{
       let newStr = value.replace(/_/g, " ");
       return newStr;
    }
  }
}