import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpIntersepterService } from './services/intercepter';
import { LoaderService } from './services/loaderService';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';


import { MatProgressBarModule} from '@angular/material/progress-bar';



//Created Ones
import { LoginComponent } from './login/login.component';
import { DefaultModule } from './layouts/default/default.module';
import { ControlMessagesComponent } from './controlmsg.component';
import { PreventLoggedInAccess } from './guards/preventLoggedInAccess.guard';

//3rd Party
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ControlMessagesComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    BlockUIModule.forRoot(),
    DefaultModule
  ],
  providers: [
    LoaderService,
    DatePipe,
    PreventLoggedInAccess,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntersepterService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
