
import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { FormControl, Validators, FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { BusinessTermService } from "../../shared/services/businessTerm.service";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "../../services/loaderService";
import { SelectAutocompleteComponent } from "mat-select-autocomplete";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from '../../shared/services/commonService.service';
import { findLast } from '@angular/compiler/src/directive_resolver';
import { HostListener } from "@angular/core";

@Component({
  selector: "app-business-term",
  templateUrl: "./business-term.component.html",
  styleUrls: ["./business-term.component.scss"],
})
export class BusinessTermComponent implements OnInit {
  @ViewChild("addNewBusinessTerm", { static: true }) public addNewBusinessTerm: TemplateRef<any>;
  @ViewChild(SelectAutocompleteComponent, { static: false }) multiSelect: SelectAutocompleteComponent;
  panelOpenState = false;
  user: any;
  // 1st form on Click of Add New
  addBusinessTermForm: FormGroup;
  submitted: boolean = false;
  nextClicked: boolean = false;
  dropdownList: any = [];
  selectedTermDomains: any = [];
  selectedRelatedTables: any = [];
  dropdownSettings: any = {};

  // 2nd form on Click of Next
  relatedTableColumnsForm: FormGroup;
  groupData: any;
  selectedRelatedTableColumns: any = [];
  saveSubmit: boolean = false;
  dropdownSettingsRelatedTableColumns: any = {};


  //Business term List
  businessTerm: FormControl;
  contentResult: Array<any> = [];
  mResponseMessage: Array<any> = [];
  currentPage: number;
  itemsPerPage: number;
  itemsPerPageFrom:number;
  itemsPerPageTo:number;
  totalItemsCount:number;
  totalItems: number;

  businessTermDomains: Array<any> = [];
  relatedTableList: Array<any> = [];
  dataClassificationList: Array<any> = [];
  relatedTablesColumnData: Array<any> = [];
  businessOwnerList: Array<any> = [];
  businessDataCatList: Array<any> = [];
  dataStewardList: Array<any> = [];
  reportingAnalyticsStewardList: Array<any> = [];
  termTaxonomyCategory: Array<any> = [];
  termTaxonomyTermType: Array<any> = [];
  termTaxonomySubCategory: Array<any> = [];
  addPageDetailsMessage: Array<any> = [];
  btGroupList: Array<any> = [];
  businessTermDomain: Array<any> = []
  criticalElementsList: Array<any> = []
  PiiElementsList: Array<any> = []

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private businessTermService: BusinessTermService,
    private commonService: CommonService,
    public modalService: NgbModal,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.criticalElementsList=['MR - Y','MR, MK - Y','Sales from HQ level - Y','Target achievement budget RKAP - Y','Y','NA'];
    this.PiiElementsList=['Yes','No','NA'];
    this.user = JSON.parse(sessionStorage.getItem('user'));
    
    this.businessTerm = new FormControl('');
    this.currentPage = 1;
    this.totalItems = 0;
    this.itemsPerPage = 25;
    this.getBusinessTermsData();
    this.initializeBusinessForm();
    this.initRelatedTableColumnsArray();


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'table_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.dropdownSettingsRelatedTableColumns = {
      singleSelection: false,
      idField: 'technical_elements_id',
      textField: 'technical_elements_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

  }

  //Get list BT
  getBusinessTermsData() {
    this.loaderService.display(true);
    this.businessTermService
      .getBusinessTermList("business_glossaries", this.currentPage, this.itemsPerPage)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.contentResult = data["Message"];
            this.contentResult = this.contentResult.slice(0, 50);
            this.totalItems = data["Total_Count"];
            this.totalItemsCount = data["Total_Count"];
            this.itemsPerPageFrom= data["first_page"]
            this.itemsPerPageTo= data["last_page"]
            this.loaderService.display(false);
            

            
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  initializeBusinessForm() {
    return this.addBusinessTermForm = this.formBuilder.group({
      term_status: ['', []],
      effective_from: ['', []],
      created_date: ['', []],
      data_steward: ['', []],
      data_owner: ['', []],
      source_system: ['', []],
      related_reference_table: ['', []],
      term_name: ['', [Validators.required]],
      term_definition: ['', [Validators.required]],
      // businessTermDomain: [this.selectedTermDomains,[Validators.required]],
      e2e_statutory_reporting: ['', []],
      e2e_statutory_analytics: ['', []],
      e2e_management_reporting: ['', []],
      e2e_management_analytics: ['', []],
      e2e_other_prepaid_analytics: ['', []],
      e2e_other_postpaid_analytics: ['', []],
      e2e_other_customer_management:['', []],
      e2e_other_digital_marketing:['', []],
      e2e_other_customer_analytics:['', []],
      e2e_other_data_insight:['', []],
      e2e_other_customer_service:['', []],
      elements_mr: ['', []],
      elements_accounting: ['', []],
      elements_marketing: ['', []],
      elements_sales: ['', []],
      elements_data_solution: ['', []],
      elements_byu: ['', []],
      elements_na: ['', []],
      data_classification: [[],[Validators.required]],
      data_classification_remarks: ['', []],
      pii_elements: [[], [Validators.required]],
      critical_data_elements: [[], [Validators.required]],
      risk_classification: ['', []],
      business_owner: [[], [Validators.required]],
      data_category: [[], [Validators.required]],
      usage_rules: ['', []],
      term_example: ['', []],
      abbreviation: [''],
      reporting_analytics_steward: [[], [Validators.required]],
      metadata_dq_steward: [[], [Validators.required]],
      business_term_domain: [[], [Validators.required]],
      business_term_group: [[], [Validators.required]],
      term_taxonomy_term_type: [[], []],
      term_taxonomy_category: [[], []],
      term_taxonomy_sub_category: [[], []],
      additional_info: ['', []],
      data_dictionary_id: [this.selectedRelatedTables, [Validators.required]],
      technical_elements: ['', []]
    });
  }

  initRelatedTableColumnsArray() {
    this.relatedTableColumnsForm = this.formBuilder.group({
      relatedTables: this.formBuilder.array([
        this.formBuilder.group({
          relatedTablesColumns: [this.selectedRelatedTableColumns, [Validators.required]],
        })

      ])
    });

  }

  newRelatedTableColumnsForm() {
    return this.formBuilder.group({
      relatedTablesColumns: [[], [Validators.required]],
    });
  }

  addNewRelatedTablesColumn() {
    this.selectedRelatedTableColumns = [];
    this.groupData = this.relatedTableColumnsForm.get('relatedTables');
    this.groupData.controls.push(this.newRelatedTableColumnsForm());
  }

  get relatedTables() {
    return <any>this.relatedTableColumnsForm.get('relatedTables');
  }

  onKeyDown(event:any) {
        if (event.target.value === "") {
          this.itemsPerPage = 10;
          this.getBusinessTermsData();
        }
        else {
          this.loaderService.display(true);
    
          this.commonService.getGlobalReferenceSearchResults("business_glossaries",event.target.value).subscribe(data => {
            if (data["Success"] > 0) {
              this.contentResult = data['Message'];
              // this.contentResult = this.contentResult.slice(0, 50);
              this.totalItems = 0; // to remove pagination from search list
              this.itemsPerPage = this.contentResult.length;
              this.totalItemsCount = data["data_length"];
             this.itemsPerPageFrom= 1;
             this.itemsPerPageTo= data["data_length"];
            }
            this.loaderService.display(false);
          },
            error => {
              this.toasterService.error("Something went wrong!");
              this.loaderService.display(false);
            })
        }
    
  }

 

  searchBusinessTerm() {
    if (this.businessTerm.value == "") {
      this.itemsPerPage = 10;
      this.getBusinessTermsData();
    }
    else {
      this.loaderService.display(true);

      this.commonService.getGlobalReferenceSearchResults("business_glossaries", this.businessTerm.value).subscribe(data => {
        if (data["Success"] > 0) {
          this.contentResult = data['Message'];
          // this.contentResult = this.contentResult.slice(0, 50);
          this.totalItems = 0; // to remove pagination from search list
          this.totalItemsCount = data["data_length"];
          this.itemsPerPageFrom= data["data_length"]
          this.itemsPerPageTo= data["data_length"]

        } else {
          this.toasterService.error(data['Message']);
        }
        this.loaderService.display(false);
      },
        error => {
          this.toasterService.error("Something went wrong!");
          this.loaderService.display(false);
        })
    }

  }

  openDetail(termName) {
    this.router.navigate(["user/business/details/", termName]);
  }

  //Add new BT
  addBusinessTerm() {
    this.getBusinessTermsAddPageDetails();
  }

  //API call for Business Onwer,Data Steward and others fields
  getBusinessTermsAddPageDetails() {
    this.loaderService.display(true);
    this.businessTermService.getBusinessTermsAddPageDetails().subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.addPageDetailsMessage = data["Message"];
       
          
          this.businessOwnerList = this.addPageDetailsMessage["business_owner"];
          this.dataStewardList = this.addPageDetailsMessage["reporting_metadata_steward"];
          this.businessTermDomain = this.addPageDetailsMessage['group_domain'];
          this.termTaxonomyCategory = this.addPageDetailsMessage["term_taxonomy_category"];
          this.termTaxonomySubCategory = this.addPageDetailsMessage["term_taxonomy_sub_category"];
          this.termTaxonomyTermType = this.addPageDetailsMessage["term_taxonomy_term_type"];
          this.reportingAnalyticsStewardList = this.addPageDetailsMessage['reporting_metadata_steward'];
          this.relatedTableList = this.addPageDetailsMessage["related_table"];
          this.dataClassificationList= this.addPageDetailsMessage['data_classification_list'];
          this.businessDataCatList=this.addPageDetailsMessage['data_category_list'];
          // this.businessTermDomains = this.addPageDetailsMessage["business_term_domain"];
          this.btGroupList= this.addPageDetailsMessage['business_term_group_list'];
          this.businessTermAdd();

        } else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  businessTermAdd() {
    this.selectedRelatedTables = [];
    this.submitted = false;

    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      centered: true,
    };

    this.modalService.open(this.addNewBusinessTerm, ngbModalOptions);
  }


  pageChanged(event: any): void {
    this.itemsPerPageTo=0;
    this.itemsPerPageFrom= 0;
    this.currentPage = event;
    this.getBusinessTermsData();

    this.businessTermService
      .getBusinessTermList(
        "business_glossaries",
        this.currentPage,
        this.itemsPerPage
      )
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.contentResult = data["Message"];

            this.contentResult = this.contentResult.slice(0, 50);
            this.totalItems = data["Total_Count"];
            this.totalItemsCount = data["Total_Count"];
            this.itemsPerPageFrom= data["first_page"];
            this.itemsPerPageTo= data["last_page"];

          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
        }
      );
  }

  //Model code for Add New Business Term
  get formControls() {
    return this.addBusinessTermForm.controls;
  }

  onNextSubmit() {
    
    
    this.submitted = true;
  if (this.addBusinessTermForm.value.e2e_statutory_reporting== true||this.addBusinessTermForm.value.e2e_statutory_reporting == "Y" ) {
      this.addBusinessTermForm.controls.e2e_statutory_reporting.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_statutory_reporting.setValue("N");
  }

  if (this.addBusinessTermForm.value.e2e_statutory_analytics== true||this.addBusinessTermForm.value.e2e_statutory_analytics == "Y" ) {
      this.addBusinessTermForm.controls.e2e_statutory_analytics.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_statutory_analytics.setValue("N");
  }

  if (this.addBusinessTermForm.value.e2e_management_reporting== true||this.addBusinessTermForm.value.e2e_management_reporting == "Y" ) {
      this.addBusinessTermForm.controls.e2e_management_reporting.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_management_reporting.setValue("N");
  }

  if (this.addBusinessTermForm.value.e2e_management_analytics== true||this.addBusinessTermForm.value.e2e_management_analytics == "Y" ) {
      this.addBusinessTermForm.controls.e2e_management_analytics.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_management_analytics.setValue("N");
  }

  if (this.addBusinessTermForm.value.elements_mr== true||this.addBusinessTermForm.value.elements_mr == "Y" ) {
      this.addBusinessTermForm.controls.elements_mr.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.elements_mr.setValue("N");
  }
  if (this.addBusinessTermForm.value.elements_accounting== true||this.addBusinessTermForm.value.elements_accounting == "Y" ) {
      this.addBusinessTermForm.controls.elements_accounting.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.elements_accounting.setValue("N");
  }
  if (this.addBusinessTermForm.value.elements_marketing== true||this.addBusinessTermForm.value.elements_marketing == "Y" ) {
      this.addBusinessTermForm.controls.elements_marketing.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.elements_marketing.setValue("N");
  }
  if (this.addBusinessTermForm.value.elements_sales== true||this.addBusinessTermForm.value.elements_sales == "Y" ) {
      this.addBusinessTermForm.controls.elements_sales.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.elements_sales.setValue("N");
  }
  if (this.addBusinessTermForm.value.elements_data_solution== true||this.addBusinessTermForm.value.elements_data_solution == "Y" ) {
      this.addBusinessTermForm.controls.elements_data_solution.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.elements_data_solution.setValue("N");
  }
  if (this.addBusinessTermForm.value.elements_byu== true||this.addBusinessTermForm.value.elements_byu == "Y" ) {
      this.addBusinessTermForm.controls.elements_byu.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.elements_byu.setValue("N");
  }
  if (this.addBusinessTermForm.value.elements_na== true||this.addBusinessTermForm.value.elements_na == "Y" ) {
      this.addBusinessTermForm.controls.elements_na.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.elements_na.setValue("N");
  }
  if (this.addBusinessTermForm.value.e2e_other_prepaid_analytics== true||this.addBusinessTermForm.value.e2e_other_prepaid_analytics == "Y" ) {
      this.addBusinessTermForm.controls.e2e_other_prepaid_analytics.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_other_prepaid_analytics.setValue("N");
  }
   if (this.addBusinessTermForm.value.e2e_other_postpaid_analytics== true||this.addBusinessTermForm.value.e2e_other_postpaid_analytics == "Y" ) {
      this.addBusinessTermForm.controls.e2e_other_postpaid_analytics.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_other_postpaid_analytics.setValue("N");
  }
  if (this.addBusinessTermForm.value.e2e_other_customer_management== true||this.addBusinessTermForm.value.e2e_other_customer_management == "Y" ) {
      this.addBusinessTermForm.controls.e2e_other_customer_management.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_other_customer_management.setValue("N");
  }
  if (this.addBusinessTermForm.value.e2e_other_digital_marketing== true||this.addBusinessTermForm.value.e2e_other_digital_marketing == "Y" ) {
      this.addBusinessTermForm.controls.e2e_other_digital_marketing.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_other_digital_marketing.setValue("N");
  }
  if (this.addBusinessTermForm.value.e2e_other_data_insight== true||this.addBusinessTermForm.value.e2e_other_data_insight == "Y" ) {
      this.addBusinessTermForm.controls.e2e_other_data_insight.setValue("Y");
  }else{
      this.addBusinessTermForm.controls.e2e_other_data_insight.setValue("N");
  }
   if (this.addBusinessTermForm.value.e2e_other_customer_analytics== true||this.addBusinessTermForm.value.e2e_other_customer_analytics == "Y" ) {
      this.addBusinessTermForm.controls.e2e_other_customer_analytics.setValue("Y");
   }else{
      this.addBusinessTermForm.controls.e2e_other_customer_analytics.setValue("N");
   } 
   
   if (this.addBusinessTermForm.value.e2e_other_customer_service== true||this.addBusinessTermForm.value.e2e_other_customer_service == "Y" ) {
      this.addBusinessTermForm.controls.e2e_other_customer_service.setValue("Y");
   }else{
      this.addBusinessTermForm.controls.e2e_other_customer_service.setValue("N");
   }
   //console.log(this.addBusinessTermForm.value);
    // stop here if form is invalid
    if (this.addBusinessTermForm.invalid) {
      return;
    }
    else {

      this.nextClicked = true;

      this.getRelatedTableColumnsList();
    }

  }

  getRelatedTableColumnsList() {
    this.loaderService.display(true);

    // to reset the existing RelatedTables Column form if user click back
    this.initRelatedTableColumnsArray();

    let selectedRelatedTables = [];

    this.selectedRelatedTables.forEach(element => {
      selectedRelatedTables.push(element.id);
    });

    this.businessTermService.getRelatedTableColumnsListForAdd(selectedRelatedTables).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.relatedTablesColumnData = data['Message'].technical_elements_data;
          for (var i = 1; i < data['Message'].technical_elements_data.length; i++) {
            this.addNewRelatedTablesColumn();
          }
        }
        else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  onItemSelect(index, event) {
    if (index > 0) {
      this.relatedTableColumnsForm.controls['relatedTables'].value[index].relatedTablesColumns.push(event);
    }
  }

  onSelectAll(index,event){
    
    this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns = [];
    this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns = event;
  }

  onDeSelectAll(index) {
    this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns = [];
  }

  onSaveSubmit() {
    this.saveSubmit = true;

    let data_dictionary_id_updated = [];

    // modifiled array for data_dictionary_id as list of IDs
    for (var val of this.addBusinessTermForm.get('data_dictionary_id').value) {
      data_dictionary_id_updated.push(val['id']);
    }
    this.addBusinessTermForm.controls.data_dictionary_id.setValue(data_dictionary_id_updated);

    this.addBusinessTermForm.controls.usage_rules.setValue(this.addBusinessTermForm.get('usage_rules').value.split(',')
    );
     
    this.getBusinessTermsAddData(this.addBusinessTermForm.value, this.relatedTableColumnsForm.value);

  }

  // call for Adding Business Term
  getBusinessTermsAddData(addBusinessFormData, relatedTableColumnsFormData) {

    this.loaderService.display(true);
    this.modalService.dismissAll();

    let finalJSONToPost = addBusinessFormData;
    finalJSONToPost.created_date = new Date();

    for (var i = 0; i < relatedTableColumnsFormData.relatedTables.length; i++) {

      for (var j = 0; j < relatedTableColumnsFormData.relatedTables[i].relatedTablesColumns.length; j++) {

        if (j != relatedTableColumnsFormData.relatedTables[i].relatedTablesColumns.length - 1) {
          finalJSONToPost.technical_elements = finalJSONToPost.technical_elements.concat(relatedTableColumnsFormData.relatedTables[i].relatedTablesColumns[j].technical_elements_name, ",");
        }
        else {
          finalJSONToPost.technical_elements = finalJSONToPost.technical_elements.concat(relatedTableColumnsFormData.relatedTables[i].relatedTablesColumns[j].technical_elements_name);
        }
      }
      if (i != relatedTableColumnsFormData.relatedTables.length - 1) {
        finalJSONToPost.technical_elements = finalJSONToPost.technical_elements.concat(';');
      }
    }

    this.businessTermService.getBusinessTermsAddData(finalJSONToPost).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.mResponseMessage = data["Message"];
          this.toasterService.success("Business term Added successfully.");
          this.getBusinessTermsData();
          this.initializeBusinessForm();
          this.initRelatedTableColumnsArray();
          this.nextClicked = false;
          this.submitted = false;
          this.saveSubmit = false;
        }
        else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

}
