import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { LoginService } from './../../../services/loginService';
import { LoaderService } from '../../../services/loaderService';
import { ProfileService } from '../../../shared/services/profile.service';
import { CommonService } from '../../../shared/services/commonService.service';
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild("userProfile", { static: true }) public userProfile: TemplateRef<any>;
  user: any;
  userImageURL: any;
  userImgFile: any;
  userImgUploadDone: boolean = false;

  constructor(private loginService: LoginService,
    private profileService: ProfileService,
    private commonService: CommonService,
    public sanitizer: DomSanitizer,
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private router: Router,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,) { }

  ngOnInit() {
    this.getUserImage();
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  getUserDetails() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  getUserImage() {
    this.profileService.getUserImageDetails().subscribe((data: any) => {

      this.createImageFromBlob(data);

    },
      error => {
        console.log(error);
      })
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.userImageURL = reader.result;
      
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  handleProfileImageUpload(e) {
    this.userImgFile = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (this.commonService.validateImgFile(this.userImgFile)) {
      this.upload(this.userImgFile);
    }
    e.target.value = "";
  }

  upload(file) {
    this.loaderService.display(true);
    this.userImgUploadDone = true;
    let formData: FormData = new FormData();
    formData.append("file", file);

    this.profileService.uploadProfileImg(formData).subscribe(
      (data) => {
        if (data['Success'] > 0) {
          this.getUserImage();
          this.userImgUploadDone = false;
          this.loaderService.display(false);
        }
        else {
          this.toasterService.error(data['Message']);
          this.loaderService.display(false);
        }
      },

      (error) => {
        this.toasterService.error('Something went wrong!');
        this.loaderService.display(false);
        this.userImgUploadDone = false;
      }
    );
  }

  // noImgUploaded(event){
  //   event.target.src = './assets/img/common/icons8-male-user-50.png';
  // }
  
  removeProfileImage() {
    this.loaderService.display(true);
    this.profileService.removeProfileImg().subscribe(data => {
      if (data['Success'] > 0) {
        this.getUserImage();
        this.toasterService.success(data['Message']);
        this.loaderService.display(false);
      }
      else {
        this.toasterService.error(data['Messsage']);
        this.loaderService.display(false);
      }
    },
      error => {
        this.toasterService.error('Something went wrong!');
        this.loaderService.display(false);
      })
  }

  logout() {
    this.loaderService.display(true);
    this.loginService.logout().subscribe(response => {
      if (response['Success'] > 0) {
        sessionStorage.clear();
        this.toasterService.success('Logged out successfully');
        this.loaderService.display(false);
        this.router.navigate(['/login']);
      }
      else {
        this.toasterService.error(response['Message']);
        this.loaderService.display(false);
      }

    },
      error => {
        this.toasterService.error(error);
        this.loaderService.display(false);
        console.log(error);
      })
  }

  openProfile() {
    this.getUserImage();
    this.getUserDetails();
    if (this.user) {
      this.modalService.open(this.userProfile, { centered: true });
    }
  }

  close() {
    this.modalService.dismissAll();
  }

}
