import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../../shared/shared.module';


import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
imports: [ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' })]
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTabsModule } from '@angular/material/tabs';
import { NgBootstrapDatetimeAngularModule } from "ng-bootstrap-datetime-angular";
import {MatExpansionModule} from '@angular/material/expansion';

// Angular Material Import
import {
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatRadioModule,
  MatInputModule,
  MatTableModule,
  MatMenuModule,
  MatIconModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,


} from '@angular/material';

import { DefaultComponent } from './default.component';
import { HomeComponent } from '../../modules/home/home.component';
import { SearchResultComponent } from '../../modules/home/search-result/search-result.component';
import { SearchResultDetailsComponent } from 'src/app/modules/home/search-result/search-result-details/search-result-details.component';
import { ReferenceComponent } from '../../modules/reference/reference.component';
import { RefResultDetailsComponent } from 'src/app/modules/reference/reference-result-details/ref-result-details.component';
import { BusinessTermComponent } from 'src/app/modules/business-term/business-term.component';
import { BusinessTermDetailComponent } from 'src/app/modules/business-term/business-term-detail/business-term-detail.component';
import { DataDictionaryComponent } from 'src/app/modules/data-dictionary/data-dictionary.component';
import { DataDictionaryDetailComponent } from 'src/app/modules/data-dictionary/data-dictionary-detail/data-dictionary-detail.component';
// import { CSVUploadModalContent } from '../../modules/home/modal/csv-upload-modal.component';
// import { CsvUploadSuccessComponent } from '../../modules/home/modal/csv-upload-success/csv-upload-success.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { AdminPageComponent } from 'src/app/modules/admin-page/admin-page.component';
import { MatListModule } from '@angular/material/list';
import { BrowserModule } from '@angular/platform-browser';
import { DataLineageComponent } from 'src/app/modules/data-lineage/data-lineage.component';
import { DataLineageDetailsComponent } from 'src/app/modules/data-lineage/data-lineage-details/data-lineage-details.component';
import { DataProfilingQualityComponent } from 'src/app/modules/data-profiling-quality/data-profiling-quality.component';
import { DataProfilingQualityDetailsComponent } from 'src/app/modules/data-profiling-quality/data-profiling-quality-details/data-profiling-quality-details.component';
import { DataProfilingComponent } from 'src/app/modules/data-profiling/data-profiling.component';
import { DataProfilingDetailsComponent } from 'src/app/modules/data-profiling/data-profiling-details/data-profiling-details.component';
import { NotificationModuleComponent } from 'src/app/modules/notification-module/notification-module.component';
import { ReportComponent } from 'src/app/modules/report/report.component';
import { ErrorMessagesComponent } from 'src/app/errormsg.component';
import { ChartsModule } from 'ng2-charts';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

@NgModule({
  declarations: [
    DefaultComponent,
    HomeComponent,
    SearchResultComponent,
    SearchResultDetailsComponent,
    BusinessTermComponent,
    DataDictionaryComponent,
    DataDictionaryDetailComponent,
    BusinessTermDetailComponent,
    ReferenceComponent,
    RefResultDetailsComponent,
    AdminPageComponent,
    DataLineageComponent,
    DataLineageDetailsComponent,
    // CSVUploadModalContent,
    // CsvUploadSuccessComponent,
    DataProfilingQualityComponent,
    DataProfilingQualityDetailsComponent,
    DataProfilingComponent,
    DataProfilingDetailsComponent,
    NotificationModuleComponent,
    ReportComponent,
    ErrorMessagesComponent,
    
    
  ],
  imports: [
    RouterModule,
    FlexLayoutModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    BlockUIModule.forRoot(),
    SelectAutocompleteModule,
    MatAutocompleteModule,
    MatSelectModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    MatTabsModule,
    MatListModule,
    BrowserModule,
    NgBootstrapDatetimeAngularModule,
    NgSelectModule,
    MatExpansionModule,
    ChartsModule,
    AutocompleteLibModule

  ],
  exports: [
  ],
  entryComponents: [
    // NgbActiveModal,
    // CSVUploadModalContent, CsvUploadSuccessComponent
  ],
  providers: [
    NgbActiveModal,
    
  ]
})
export class DefaultModule { }
