import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LoaderService } from 'src/app/services/loaderService';
import { ToastrService } from 'ngx-toastr';
import { BusinessTermService } from 'src/app/shared/services/businessTerm.service';
import { NgbModal, NgbActiveModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { MatDialog } from '@angular/material';
import { requireCheckboxesToBeCheckedValidator } from 'src/app/modules/business-term/require-checkboxes-to-be-checked.validator';

//import { environment } from "../../../environments/environment";
@Component({
  selector: 'app-business-term-detail',
  templateUrl: './business-term-detail.component.html',
  styleUrls: ['./business-term-detail.component.scss']
})
export class BusinessTermDetailComponent implements OnInit {
  @ViewChild("editBusinessTerm", { static: true }) public editBusinessTerm: TemplateRef<any>;
  @ViewChild(SelectAutocompleteComponent, { static: false }) multiSelect: SelectAutocompleteComponent;
  @ViewChild("editSuccess", { static: true }) public editSuccess: TemplateRef<any>;

  user: any;
  editBusinessTermForm: FormGroup;
  submitted: boolean = false;
  businessTermName: string;
  contentResult: Array<any> = [];
  editPageDetailsMessage: Array<any> = [];
  reportingMetadataSteward: Array<any> = [];
  reportingMetadataStewardMain: Array<any> = [];
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  changeHistoryList: Array<any> = [];
  relatedTablesList: Array<any> = [];
  subjectAreaList: Array<any> = [];
  relatedTableList: Array<any> = [];
  businessOwnerList: Array<any> = []
  businessDataCatList: Array<any> = [];
  dataStewardList: Array<any> = []
  businessTermDomain1: Array<any> = []
  termTaxonomyCategory: Array<any> = []
  criticalElementsList: Array<any> = []
  PiiElementsList: Array<any> = []
  dataClassificationList: Array<any> = []
  businessTermGroupList: Array<any> = []
  termTaxonomyTermType: Array<any> = []
  termTaxonomySubCategory: Array<any> = [];
  selectedItems: Array<any> = [];
  relatedTableDropdownSettings: any = {};
  relatedTableSelectedItems: Array<any> = [];
  usage_rules: Array<any> = [];
  dropdownList = [];

  isChecked1:any=false;
  isChecked2:any=false;
  nextClicked: boolean = false;
  requireOneCheckboxToBeChecked: boolean = false;
  relatedTablesColumnData: Array<any> = [];
  selectedRelatedTables: any = [];
  baseApiUrl: string

  // 2nd form on Click of Next
  relatedTableColumnsForm: FormGroup;
  groupData: any;
  selectedRelatedTableColumns: any = [];
  saveSubmit: boolean = false;
  dropdownSettingsRelatedTableColumns: any = {};
  panelOpenState = false;

  //Ng-select
  dataCategoryEditSelect: any;

  constructor(private _location: Location,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private businessTermService: BusinessTermService,
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog) { }


  ngOnInit() {
    
    this.criticalElementsList=['MR - Y','MR, MK - Y','Sales from HQ level - Y','Target achievement budget RKAP - Y','Y','NA'];
    this.PiiElementsList=['Yes','No','NA'];
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.initializeBusinessForm()

    this.relatedTableDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'table_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.dropdownSettingsRelatedTableColumns = {
      singleSelection: false,
      idField: 'technical_elements_id',
      textField: 'technical_elements_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.activatedRoute.params.subscribe((params: Params) => {
      this.businessTermName = params['id'];
      this.getBusinessTermsDetailsData();
      this.getBusinessTermsEditPageDetails();
    });
  }

  redirectBack() {
    this._location.back();
  }

  edit() {
    //this.getBusinessTermsEditPageDetails()
    // Open Edit form Modal here
    this.businessTermEdit();
    
  }

  //API call for Details 
  getBusinessTermsDetailsData() {
    this.loaderService.display(true);
    this.businessTermService.getBusinessTermsDetailsData(this.businessTermName).subscribe(data => {

      if (data['Success'] > 0) {
        this.contentResult = data['Message'];
        this.changeHistoryList = data['Change_History_Data'];
        this.relatedTablesList = data['Related_Tables_Data'];

        this.subjectAreaList = data['Subject_Area'];
        this.selectedItems = this.subjectAreaList;
      } else {
        this.toasterService.error(data['Message']);
      }
      this.loaderService.display(false);
    },
      error => {
        this.toasterService.error('Something went wrong!');
      })
  }

  //API call for Business Owner ,Data Steward and others fields
  getBusinessTermsEditPageDetails() {
    
    
    this.loaderService.display(true);
    this.businessTermService.getBusinessTermsEditPageDetails(this.businessTermName).subscribe(data => {

      if (data["Success"] > 0) {
        this.editPageDetailsMessage = data['Message'];

        this.businessOwnerList = this.editPageDetailsMessage['business_owner'];
        this.dataStewardList = this.editPageDetailsMessage['data_steward'];
        this.dataClassificationList= this.editPageDetailsMessage['data_classification_list'];
        this.businessTermGroupList= this.editPageDetailsMessage['business_term_group_list'];

        this.businessTermDomain1 = this.editPageDetailsMessage['group_domain'];
        this.termTaxonomyCategory = this.editPageDetailsMessage['term_taxonomy_category'];

        this.termTaxonomySubCategory = this.editPageDetailsMessage['term_taxonomy_sub_category'];
        this.termTaxonomyTermType = this.editPageDetailsMessage['term_taxonomy_term_type'];
        this.reportingMetadataStewardMain = this.editPageDetailsMessage['reporting_metadata_steward'];
        this.businessDataCatList=this.editPageDetailsMessage['data_category_list'];

        this.reportingMetadataStewardMain.forEach(element => {
          this.reportingMetadataSteward.push(element.user_name);
        });


        this.relatedTableList = this.editPageDetailsMessage['related_table'];

        this.relatedTableSelectedItems = [];
        this.relatedTableList.forEach(element => {
          if (this.editPageDetailsMessage['selected_table_id'] != null) {
            this.editPageDetailsMessage['selected_table_id'].forEach(id => {
              if (element.id === id) {
                this.relatedTableSelectedItems.push(element);
              }
            })
          }
          else {
            this.relatedTableSelectedItems = [];
          };
        });

        

      } else {
        this.toasterService.error(data['Message']);
      }
      this.loaderService.display(false);
    },
      error => {
        this.toasterService.error("Something went wrong!")
      })
  }

  businessTermEdit() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };

    this.editBusinessTermForm.controls.term_name.setValue(this.editPageDetailsMessage['term_name']);
    this.editBusinessTermForm.controls.term_definition.setValue(this.editPageDetailsMessage['term_definition']);
    this.editBusinessTermForm.controls.business_owner.setValue(this.businessOwnerList[this.businessOwnerList.indexOf(this.contentResult['business_owner'])]);
   // this.editBusinessTermForm.controls.data_category.setValue(this.businessDataCatList[this.businessDataCatList.indexOf(this.contentResult['data_category'])]);
    this.dataCategoryEditSelect=this.businessDataCatList[this.businessDataCatList.indexOf(this.contentResult['data_category'])];
    
    this.editBusinessTermForm.controls.reporting_analytics_steward.setValue(this.reportingMetadataSteward[this.reportingMetadataSteward.indexOf(this.contentResult['reporting_analytics_steward'])]);
    this.editBusinessTermForm.controls.usage_rules.setValue(this.editPageDetailsMessage['usage_rules']);
    this.editBusinessTermForm.controls.pii_elements.setValue(this.editPageDetailsMessage['pii_elements']);
    
    this.editBusinessTermForm.controls.data_classification.setValue(this.editPageDetailsMessage['data_classification']);
    this.editBusinessTermForm.controls.critical_data_elements.setValue(this.editPageDetailsMessage['critical_data_elements']);
    this.editBusinessTermForm.controls.risk_classification.setValue(this.editPageDetailsMessage['risk_classification']);
    this.editBusinessTermForm.controls.term_example.setValue(this.editPageDetailsMessage['term_example']);
    this.editBusinessTermForm.controls.abbreviation.setValue(this.editPageDetailsMessage['abbreviation']);
    this.editBusinessTermForm.controls.metadata_dq_steward.setValue(this.reportingMetadataSteward[this.reportingMetadataSteward.indexOf(this.contentResult['metadata_dq_steward'])]);
    this.editBusinessTermForm.controls.business_term_domain.setValue(this.businessTermDomain1[this.businessTermDomain1.indexOf(this.contentResult['business_term_domain'])]);
   

    this.editBusinessTermForm.controls.business_term_group.setValue(this.businessTermGroupList[this.businessTermGroupList.indexOf(this.contentResult['business_term_group'])]);
    this.editBusinessTermForm.controls.additional_info.setValue(this.editPageDetailsMessage['additional_info']);

    this.editBusinessTermForm.controls.term_taxonomy_term_type.setValue(this.termTaxonomyTermType[this.termTaxonomyTermType.indexOf(this.contentResult['term_taxonomy_term_type'])]);
    this.editBusinessTermForm.controls.term_taxonomy_category.setValue(this.termTaxonomyCategory[this.termTaxonomyCategory.indexOf(this.contentResult['term_taxonomy_category'])]);

    this.editBusinessTermForm.controls.term_taxonomy_sub_category.setValue(this.termTaxonomySubCategory[this.termTaxonomySubCategory.indexOf(this.contentResult['term_taxonomy_sub_category'])]);
    this.editBusinessTermForm.controls.data_dictionary_id.setValue(this.relatedTableSelectedItems);
    if (this.editPageDetailsMessage['e2e_statutory_reporting'] === "Y" || this.editPageDetailsMessage['e2e_statutory_reporting'] === true) {
      this.editBusinessTermForm.controls.e2e_statutory_reporting.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_statutory_analytics'] === "Y" || this.editPageDetailsMessage['e2e_statutory_analytics'] === true) {
      this.editBusinessTermForm.controls.e2e_statutory_analytics.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_management_reporting'] === "Y" || this.editPageDetailsMessage['e2e_management_reporting'] === true) {
      this.editBusinessTermForm.controls.e2e_management_reporting.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_management_analytics'] === "Y" || this.editPageDetailsMessage['e2e_management_analytics'] === true) {
      this.editBusinessTermForm.controls.e2e_management_analytics.setValue("Y");
    }
    //.log(JSON.stringify(this.editPageDetailsMessage))
    if (this.editPageDetailsMessage['elements_mr'] === "Y" || this.editPageDetailsMessage['elements_mr'] === true) {
      this.editBusinessTermForm.controls.elements_mr.setValue("Y");
    }
    if (this.editPageDetailsMessage['elements_accounting'] === "Y" || this.editPageDetailsMessage['elements_accounting'] === true) {
      this.editBusinessTermForm.controls.elements_accounting.setValue("Y");
    }
    if (this.editPageDetailsMessage['elements_marketing'] === "Y" || this.editPageDetailsMessage['elements_marketing'] === true) {
      this.editBusinessTermForm.controls.elements_marketing.setValue("Y");
    }
    if (this.editPageDetailsMessage['elements_sales'] === "Y" || this.editPageDetailsMessage['elements_sales'] === true) {
      this.editBusinessTermForm.controls.elements_sales.setValue("Y");
    }
    if (this.editPageDetailsMessage['elements_na'] === "Y" || this.editPageDetailsMessage['elements_na'] === true) {
      this.editBusinessTermForm.controls.elements_na.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_other_prepaid_analytics'] === "Y" || this.editPageDetailsMessage['e2e_other_prepaid_analytics'] === true) {
      this.editBusinessTermForm.controls.e2e_other_prepaid_analytics.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_other_postpaid_analytics'] === "Y" || this.editPageDetailsMessage['e2e_other_postpaid_analytics'] === true) {
      this.editBusinessTermForm.controls.e2e_other_postpaid_analytics.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_other_customer_management'] === "Y" || this.editPageDetailsMessage['e2e_other_customer_management'] === true) {
      this.editBusinessTermForm.controls.e2e_other_customer_management.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_other_digital_marketing'] === "Y" || this.editPageDetailsMessage['e2e_other_digital_marketing'] === true) {
      this.editBusinessTermForm.controls.e2e_other_digital_marketing.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_other_customer_analytics'] === "Y" || this.editPageDetailsMessage['e2e_other_customer_analytics'] === true) {
      this.editBusinessTermForm.controls.e2e_other_customer_analytics.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_other_data_insight'] === "Y" || this.editPageDetailsMessage['e2e_other_data_insight'] === true) {
      this.editBusinessTermForm.controls.e2e_other_data_insight.setValue("Y");
    }
    if (this.editPageDetailsMessage['e2e_other_customer_service'] === "Y" || this.editPageDetailsMessage['e2e_other_customer_service'] === true) {
      this.editBusinessTermForm.controls.e2e_other_customer_service.setValue("Y");
    }
    if (this.editPageDetailsMessage['elements_data_solution'] === "Y" || this.editPageDetailsMessage['elements_data_solution'] === true) {
      this.editBusinessTermForm.controls.elements_data_solution.setValue("Y");
    }
    if (this.editPageDetailsMessage['elements_byu'] === "Y" || this.editPageDetailsMessage['elements_byu'] === true) {
      this.editBusinessTermForm.controls.elements_byu.setValue("Y");
    }
    this.editBusinessTermForm.controls.data_classification_remarks.setValue(this.editPageDetailsMessage['data_classification_remarks']);
    
    this.modalService.open(this.editBusinessTerm, ngbModalOptions);

  }

 
  

  // convenience getter for easy access to form fields
  get formControls() { return this.editBusinessTermForm.controls; }


  initializeBusinessForm() {
    this.editBusinessTermForm = this.formBuilder.group({
      term_status: ['', []],
      effective_from: ['', []],
      created_date: ['', []],
      data_steward: ['', []],
      data_owner: ['', []],
      source_system: ['', []],
      related_reference_table: ['', []],
      term_name: ['', []],
      term_definition: ['', [Validators.required]],
      e2e_statutory_reporting: ['', []],
      e2e_statutory_analytics: ['', []],
      e2e_management_reporting: ['', []],
      e2e_management_analytics: ['', []],
      e2e_other_prepaid_analytics: ['', []],
      e2e_other_postpaid_analytics: ['', []],
      e2e_other_customer_management:['', []],
      e2e_other_digital_marketing:['', []],
      e2e_other_customer_analytics:['', []],
      e2e_other_data_insight:['', []],
      e2e_other_customer_service:['', []],
      elements_mr: ['', []],
      elements_accounting: ['', []],
      elements_marketing: ['', []],
      elements_sales: ['', []],
      elements_data_solution: ['', []],
      elements_byu: ['', []],
      elements_na: ['', []],
      data_classification: ['',[]],
      data_classification_remarks: ['', []],
      pii_elements: ['', [Validators.required]],
      critical_data_elements: ['', [Validators.required]],
      risk_classification: ['', []],
      business_owner: ['', [Validators.required]],
      data_category: ['', [Validators.required]],
      usage_rules: ['',],
      term_example: ['', []],
      abbreviation: [''],
      metadata_dq_steward: ['',  []],
      term_taxonomy_term_type: ['',  []],
      term_taxonomy_category: ['',  []],
      term_taxonomy_sub_category: ['', []],
      additional_info: ['', []],
      reporting_analytics_steward: ['', [Validators.required]],
      business_term_domain: ['',  [Validators.required]],
      business_term_group: ['',  [Validators.required]],
      data_dictionary_id: [this.relatedTableSelectedItems,  [Validators.required]],
      technical_elements: ['', []],
      
    });
  }

  initRelatedTableColumnsArray() {
    this.selectedRelatedTableColumns=[];
    this.relatedTableColumnsForm = this.formBuilder.group({
      relatedTables: this.formBuilder.array([
        this.formBuilder.group({
          relatedTablesColumns: [this.selectedRelatedTableColumns, [Validators.required]],
        })
      ])
    });
  }

  newRelatedTableColumnsForm() {
    return this.formBuilder.group({
      relatedTablesColumns: [[], [Validators.required]],
    });
  }

  addNewRelatedTablesColumn() {
    this.selectedRelatedTableColumns = [];
    this.groupData = this.relatedTableColumnsForm.get('relatedTables');
    this.groupData.controls.push(this.newRelatedTableColumnsForm());
  }

  get relatedTables() {
    return <any>this.relatedTableColumnsForm.get('relatedTables');
  }

  onNextSubmit() {
    this.submitted = true;
            
    //console.log(JSON.stringify(this.editBusinessTermForm.value));
    
    //alert(JSON.stringify(this.editBusinessTermForm.value))
    // stop here if form is invalid
    if (this.editBusinessTermForm.value.e2e_statutory_reporting== true||this.editBusinessTermForm.value.e2e_statutory_reporting == "Y" ) {
        this.editBusinessTermForm.controls.e2e_statutory_reporting.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_statutory_reporting.setValue("N");
    }

    if (this.editBusinessTermForm.value.e2e_statutory_analytics== true||this.editBusinessTermForm.value.e2e_statutory_analytics == "Y" ) {
        this.editBusinessTermForm.controls.e2e_statutory_analytics.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_statutory_analytics.setValue("N");
    }

    if (this.editBusinessTermForm.value.e2e_management_reporting== true||this.editBusinessTermForm.value.e2e_management_reporting == "Y" ) {
        this.editBusinessTermForm.controls.e2e_management_reporting.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_management_reporting.setValue("N");
    }

    if (this.editBusinessTermForm.value.e2e_management_analytics== true||this.editBusinessTermForm.value.e2e_management_analytics == "Y" ) {
        this.editBusinessTermForm.controls.e2e_management_analytics.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_management_analytics.setValue("N");
    }

    if (this.editBusinessTermForm.value.elements_mr== true||this.editBusinessTermForm.value.elements_mr == "Y" ) {
        this.editBusinessTermForm.controls.elements_mr.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.elements_mr.setValue("N");
    }
    if (this.editBusinessTermForm.value.elements_accounting== true||this.editBusinessTermForm.value.elements_accounting == "Y" ) {
        this.editBusinessTermForm.controls.elements_accounting.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.elements_accounting.setValue("N");
    }
    if (this.editBusinessTermForm.value.elements_marketing== true||this.editBusinessTermForm.value.elements_marketing == "Y" ) {
        this.editBusinessTermForm.controls.elements_marketing.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.elements_marketing.setValue("N");
    }
    if (this.editBusinessTermForm.value.elements_sales== true||this.editBusinessTermForm.value.elements_sales == "Y" ) {
        this.editBusinessTermForm.controls.elements_sales.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.elements_sales.setValue("N");
    }
    if (this.editBusinessTermForm.value.elements_na== true||this.editBusinessTermForm.value.elements_na == "Y" ) {
        this.editBusinessTermForm.controls.elements_na.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.elements_na.setValue("N");
    }
    if (this.editBusinessTermForm.value.e2e_other_prepaid_analytics== true||this.editBusinessTermForm.value.e2e_other_prepaid_analytics == "Y" ) {
        this.editBusinessTermForm.controls.e2e_other_prepaid_analytics.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_other_prepaid_analytics.setValue("N");
    }
    if (this.editBusinessTermForm.value.e2e_other_postpaid_analytics== true||this.editBusinessTermForm.value.e2e_other_postpaid_analytics == "Y" ) {
        this.editBusinessTermForm.controls.e2e_other_postpaid_analytics.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_other_postpaid_analytics.setValue("N");
    }
    if (this.editBusinessTermForm.value.e2e_other_customer_management== true||this.editBusinessTermForm.value.e2e_other_customer_management == "Y" ) {
        this.editBusinessTermForm.controls.e2e_other_customer_management.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_other_customer_management.setValue("N");
    }
    if (this.editBusinessTermForm.value.e2e_other_digital_marketing== true||this.editBusinessTermForm.value.e2e_other_digital_marketing == "Y" ) {
        this.editBusinessTermForm.controls.e2e_other_digital_marketing.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_other_digital_marketing.setValue("N");
    }
    if (this.editBusinessTermForm.value.e2e_other_customer_analytics== true||this.editBusinessTermForm.value.e2e_other_customer_analytics == "Y" ) {
        this.editBusinessTermForm.controls.e2e_other_customer_analytics.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_other_customer_analytics.setValue("N");
    }
    if (this.editBusinessTermForm.value.e2e_other_data_insight== true||this.editBusinessTermForm.value.e2e_other_data_insight == "Y" ) {
        this.editBusinessTermForm.controls.e2e_other_data_insight.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_other_data_insight.setValue("N");
    }
    if (this.editBusinessTermForm.value.e2e_other_customer_service== true||this.editBusinessTermForm.value.e2e_other_customer_service == "Y" ) {
        this.editBusinessTermForm.controls.e2e_other_customer_service.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.e2e_other_customer_service.setValue("N");
    }
    if (this.editBusinessTermForm.value.elements_data_solution== true||this.editBusinessTermForm.value.elements_data_solution == "Y" ) {
        this.editBusinessTermForm.controls.elements_data_solution.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.elements_data_solution.setValue("N");
    }
     if (this.editBusinessTermForm.value.elements_byu== true||this.editBusinessTermForm.value.elements_byu == "Y" ) {
        this.editBusinessTermForm.controls.elements_byu.setValue("Y");
    }else{
        this.editBusinessTermForm.controls.elements_byu.setValue("N");
    }
    
    if (this.editBusinessTermForm.invalid) {
      return;
    }
    else {
      this.nextClicked = true;
      this.getRelatedTableColumnsList();
    }
  }

  getRelatedTableColumnsList() {
    this.loaderService.display(true);
    // to reset the existing RelatedTables Column form if user click back
    this.initRelatedTableColumnsArray();

    let relatedTableSelectedItems = [];

    this.relatedTableSelectedItems.forEach(element => {
      relatedTableSelectedItems.push(element.id);
    });

    this.businessTermService.getRelatedTableColumnsList(relatedTableSelectedItems, this.businessTermName).subscribe(
      (data) => {
        if (data["Success"] > 0) {
          this.relatedTablesColumnData = data['Message'].technical_elements_data;
          for (var i = 1; i < data['Message'].technical_elements_data.length; i++) {
            this.addNewRelatedTablesColumn();
          }
          this.fillRelatedTableColumnsForm(data['Message']);
        }
        else {
          this.toasterService.error(data["Message"]);
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.toasterService.error("Something went wrong!");
      }
    );
  }

  fillRelatedTableColumnsForm(technicalElementsData) {
    this.selectedRelatedTableColumns=[];
    technicalElementsData.selected_technical_elements.forEach((element, index) => {
      for (var i = 0; i < element.technical_element_name.length; i++) {
        // if (index == 0) {
        technicalElementsData.technical_elements_data.forEach(record => {
          if (element.selected_table === record.table_name) {
            record.technical_elements_details.forEach(obj => {
              if (obj.technical_elements_name === element.technical_element_name[i]) {

                if (index == 0) {
                  this.selectedRelatedTableColumns.push(obj);
                  this.relatedTableColumnsForm.controls.relatedTables['controls'][0].controls.relatedTablesColumns.setValue(this.selectedRelatedTableColumns);
                }
                else {
                  this.relatedTableColumnsForm.controls.relatedTables['controls'][index].controls.relatedTablesColumns.value.push(obj);

                }
              }
            });
          }
        });
      }

    });
  }

  onItemSelect(index, event) {
    if (index > 0) {
      this.relatedTableColumnsForm.controls['relatedTables'].value[index].relatedTablesColumns.push(event);
    }
  }

  onItemDeSelect(index, event) {

    for (let i = 0; i < this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns.length; i++) {

      if (this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns[i].technical_elements_id === event.technical_elements_id) {

        this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns.splice(i, 1);
      }
    }

  }

  onSelectAll(index, event) {

    this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns = [];
    this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns = event;
  }

  onDeSelectAll(index) {
    this.relatedTableColumnsForm.value.relatedTables[index].relatedTablesColumns = [];
  }

  onSaveSubmit() {
    this.saveSubmit = true;

    let data_dictionary_id_updated = [];

    // modifiled array for data_dictionary_id as list of IDs
    for (var val of this.editBusinessTermForm.get('data_dictionary_id').value) {
      data_dictionary_id_updated.push(val['id']);
    }
    this.editBusinessTermForm.controls.data_dictionary_id.setValue(data_dictionary_id_updated);

    if (typeof (this.editBusinessTermForm.get('usage_rules').value) === "string") {
      this.editBusinessTermForm.controls.usage_rules.setValue((this.editBusinessTermForm.get('usage_rules').value).split(','));
    }
    //console.log(JSON.stringify(this.relatedTableColumnsForm.value));
    
    this.getBusinessTermsEditBTData(this.editBusinessTermForm.value, this.relatedTableColumnsForm.value);

  }

  // call for edit Business Term
  getBusinessTermsEditBTData(editBusinessFormData, relatedTableColumnsFormData) {

    this.loaderService.display(true);
    this.modalService.dismissAll();

    let finalJSONToPost = editBusinessFormData;

    for (var i = 0; i < relatedTableColumnsFormData.relatedTables.length; i++) {

      for (var j = 0; j < relatedTableColumnsFormData.relatedTables[i].relatedTablesColumns.length; j++) {

        if (j != relatedTableColumnsFormData.relatedTables[i].relatedTablesColumns.length - 1) {
          finalJSONToPost.technical_elements = finalJSONToPost.technical_elements.concat(relatedTableColumnsFormData.relatedTables[i].relatedTablesColumns[j].technical_elements_name, ",");
        }
        else {
          finalJSONToPost.technical_elements = finalJSONToPost.technical_elements.concat(relatedTableColumnsFormData.relatedTables[i].relatedTablesColumns[j].technical_elements_name);
        }
      }
      if (i != relatedTableColumnsFormData.relatedTables.length - 1) {
        finalJSONToPost.technical_elements = finalJSONToPost.technical_elements.concat(';');
      }
    }

    this.getBusinessTermsEditData(finalJSONToPost);

  }

  // API call for edit submission
  getBusinessTermsEditData(data) {
    this.loaderService.display(true);
    this.modalService.dismissAll();
    this.businessTermService.getBusinessTermsEditData(this.businessTermName, data).subscribe(data => {

      if (data["Success"] > 0) {
        // this.editPageDetailsMessage = data['Message'];
        this.toasterService.success('Business Term updated successfully.');
        this.getBusinessTermsDetailsData();
        this.initializeBusinessForm();
        this.initRelatedTableColumnsArray();
        this.nextClicked = false;
        this.submitted = false;
        this.saveSubmit = false;
        this.getBusinessTermsEditPageDetails()
      } else {
        this.toasterService.error(data['Message']);
      }
      this.loaderService.display(false);
    },
      error => {
        this.toasterService.error('Something went wrong!');
      })
  }

  close() {
    this.modalService.dismissAll();
  }

  viewDataDicDetails(tableId){
    this.router.navigate(["user/dataDictionary/details", tableId]);
  }

}
