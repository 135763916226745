import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReferenceService } from '../../shared/services/reference.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loaderService';
import { CommonService } from 'src/app/shared/services/commonService.service';
import { SharedService } from 'src/app/shared/services/shared-service.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal, NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {
  @ViewChild("workFlowPopUp", { static: true }) public workFlowPopUp: TemplateRef<any>;
  @ViewChild("delegationPopUp", { static: true }) public delegationPopUp: TemplateRef<any>;
  @ViewChild("delegationSuccess", { static: true }) public delegationSuccess: TemplateRef<any>;
  @BlockUI() blockUI: NgBlockUI;
  searchReferenceInput = new FormControl;
  delegationUserInput: string;
  contentResult: Array<any>;
  recordsForDownload: Array<any>;
  allRecordsSelected: boolean;
  totalItems: number;
  currentPage: number;
  itemsPerPage: number;
  searchResult: any;
  searchResultArray: Array<any>;
  referenceName: string;
  statusHistory: Array<any>;
  selectedUser: boolean;
  selectedUserIndex: string;
  usersList: Array<any>;
  minDate = new Date();
  date = new FormControl(new Date());
  startDateModel: NgbDateStruct;
  minStartDate: NgbDateStruct;
  endDateModel: NgbDateStruct;
  itemsPerPageFrom:number;
  itemsPerPageTo:number;
  totalItemsCount:number;

  constructor(
    private router: Router,
    private refService: ReferenceService,
    private loaderService: LoaderService,
    private toasterService: ToastrService,
    private commonService: CommonService,
    private sharedService: SharedService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private calendar: NgbCalendar
  ) { }

  ngOnInit() {
    this.searchReferenceInput = new FormControl('');
    this.delegationUserInput = "";
    this.totalItems = 0;
    this.currentPage = 1;
    this.contentResult = [];
    this.itemsPerPage = 50;
    this.recordsForDownload = [];
    this.referenceName = '';
    this.statusHistory = [];
    this.selectedUser = false;
    this.usersList = [];
    this.minStartDate = this.calendar.getToday();
    this.getViewList();

  }

  getViewList() {
    this.loaderService.display(true);

    this.refService.getReferenceList(this.currentPage, this.itemsPerPage).subscribe(result => {
      if (result['Success'] > 0) {
        this.contentResult = result['Message'];
        
        this.totalItems = result['Total_Count'];
        // this.contentResult = this.contentResult.slice(0, 50);
        this.contentResult.forEach(element => {
          Object.assign(element, { selected: false });
        });
        this.totalItems = result['Message'].length;
        this.totalItemsCount = result["Total_Count"];
        this.itemsPerPageFrom= result["first_page"]
        this.itemsPerPageTo= result["last_page"]
        this.loaderService.display(false);
      }
      else {
        this.toasterService.error(result['Message']);
        this.loaderService.display(false);
      }
    },
      error => {
        this.toasterService.error('Something went wrong!');
        this.loaderService.display(false);
      });
  }

  searchReference() {
    if (this.searchReferenceInput.value == "") {
      this.getViewList();
    }
    else {
      this.loaderService.display(true);
      this.commonService.getReferenceSearchResults(this.searchReferenceInput.value).subscribe(result => {
        this.contentResult = result['Message'];
        // this.contentResult = this.contentResult.slice(0, 50);

        this.totalItems = 0; // to remove pagination on Search results

        this.contentResult.forEach(element => {
          Object.assign(element, { selected: false });
        });
        // this.totalItems = result['Message'].length;
        this.totalItemsCount = result["data_length"];
        this.itemsPerPageFrom= 1;
        this.itemsPerPageTo= result["data_length"];
        this.loaderService.display(false);
      },
        error => {
          this.loaderService.display(false);
          this.toasterService.error(error);
        })
    }

  }

  changeSortOrder(order) {
    if (order) {
      // Get list again based on order
    }
  }

  selectedAllRecordsForDownload(event) {
    this.recordsForDownload = [];
    this.allRecordsSelected = true;

    if (event.checked) {
      this.contentResult.forEach(element => {
        var refObj = {
          'Reference_Name': element['_source'].Reference_Name,
          'UI_File_Name': element['_source'].UI_File_Name
        }
        this.recordsForDownload.push(refObj);
      });

      this.contentResult.forEach(element => {
        element.selected = true;
      });

    } else {

      this.recordsForDownload = [];
      this.allRecordsSelected = false;

      this.contentResult.forEach(element => {
        element.selected = false;
      });

    }

  }

  selectMultiRecordsForDownload(reference_Name, refFileName, event) {
    if (event.checked) {
      var refObj = {
        'Reference_Name': reference_Name,
        'UI_File_Name': refFileName
      }
      this.recordsForDownload.push(refObj);

      if (this.recordsForDownload.length == this.contentResult.length) {
        this.allRecordsSelected = true;
      }
      else {
        this.allRecordsSelected = false;
      }
    } else {
      this.recordsForDownload.splice(this.recordsForDownload.map(function(obj) { return obj.Reference_Name; }).indexOf(reference_Name), 1);

      this.contentResult.forEach(element => {
        if (element['_source'].Reference_Name === reference_Name) {
          element.selected = false;
        }
      });

      this.allRecordsSelected = false;
    }
  }

  downloadServices(trigger,recordsForDownload) {
    if(trigger == 'button'){
      recordsForDownload.forEach(element => {
        this.blockUI.start('Download in progress...Kindly wait till download completes');
  
        this.commonService.downloadServices(element.Reference_Name).subscribe(data => {
          if (data["Error"] > 0) {
            this.toasterService.error("Something went wrong!");
          }
          else {
            let url = window.URL.createObjectURL(data);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = element.UI_File_Name;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
  
            this.toasterService.success("File downloaded");
          }
          this.blockUI.stop();
        },
          (error) => {
            this.toasterService.error("Something went wrong!");
          }
        );
      });
    }
    else if(trigger == 'icon'){
      recordsForDownload.forEach(element => {
        this.blockUI.start('Download in progress...Kindly wait till download completes');
  
        this.commonService.downloadServices(element.Reference_Name).subscribe(data => {
          if (data["Error"] > 0) {
            this.toasterService.error("Something went wrong!");
          }
          else {
            let url = window.URL.createObjectURL(data);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = element;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
  
            this.toasterService.success("File downloaded");
          }
          this.blockUI.stop();
        },
          (error) => {
            this.toasterService.error("Something went wrong!");
          }
        );
      });
    }
  }

  getRefDetails(id,Disabled,elasticId) {
    //this.sharedService.setCollectionDate(date);
    this.router.navigate(['user/reference/details/', id,{'disabled':Disabled,'eId':elasticId}]);
  }

  pageChanged(event: any): void {
    this.currentPage = event;

    this.getViewList();
  }

  showWorkFlow(reference_Name) {
    this.loaderService.display(true);
    this.referenceName = reference_Name;
    this.refService.getRefStatusHistory(reference_Name).subscribe(data => {
      if (data["Success"] > 0) {
        this.statusHistory = data["Message"];
        if (this.statusHistory.length > 0) {
          this.modalService.open(this.workFlowPopUp, { centered: true });
        }
      }
      else {
        this.toasterService.error("Something went wrong!");

      }
      this.loaderService.display(false);
    },
      error => {
        this.loaderService.display(false);
        this.toasterService.error("Something went wrong!");
      })
  }

  showDelegation(reference_Name) {
    this.loaderService.display(true);
    this.referenceName = reference_Name;
    this.refService.getDelegationUsersList().subscribe(data => {
      if (data["Success"] > 0) {
        this.usersList = data["Message"];
        
        if (this.usersList.length > 0) {
          this.modalService.open(this.delegationPopUp, { centered: true });
        }
      }
      else {
        this.toasterService.error("Something went wrong!");
      }
      this.loaderService.display(false);
    },
      error => {
        this.loaderService.display(false);
        this.toasterService.error("Something went wrong!");
      })
  }

  selectUserForDelegation(user) {
    this.selectedUser = true;
    this.selectedUserIndex = user;
  }

  delegate() {
    this.refService.doDelegation(this.sharedService.formatDate(this.startDateModel), this.sharedService.formatDate(this.endDateModel), this.selectedUserIndex).subscribe(data => {
      if (data['Success'] > 0) {
        this.modalService.dismissAll();
        this.resetDelegation();
        this.modalService.open(this.delegationSuccess, { centered: true });
      }
      else {
        this.toasterService.error(data['Message']);
      }
    },
      error => {
        this.toasterService.error('Something went wrong!');
      })
  }

  resetDelegation() {
    this.startDateModel = null;
    this.endDateModel = null;
    this.selectedUserIndex = '';
  }

  close() {
    this.modalService.dismissAll();
  }

}
