import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as Chart from 'chart.js';
import { SharedService } from 'src/app/shared/services/shared-service.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loaderService';
import { DataProfileService } from 'src/app/shared/services/dataProfile.service';
import { element } from 'protractor';
import { Label } from 'ng2-charts';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-data-profiling',
  templateUrl: './data-profiling.component.html',
  styleUrls: ['./data-profiling.component.scss']
})
export class DataProfilingComponent implements OnInit {

    //profiling
    startDateModel: NgbDateStruct;
    minStartDate: NgbDateStruct;
    endDateModel: NgbDateStruct;
    dateTimeForm: FormGroup;
    selectFilter: FormGroup
    pieChartProfile1: any
    pieChartProfile2:any
    contentResult:any;
    responseDateList:Array<any>;
    tableDetails: Array<any>;
    responseTableList:any;
    tableName: any;
    collectionDate: string;
    dateDqDaily: Array<any>;
    dateFilterLenght:any;

    dataElements: Array<any>;
    selectedDataElement: any;
    rseChartList:any;
    selectedDpDaily:any;
    // testArrayForChart:Array<any>=[1,2,3,4,3,4,5,6,7,8,9,10,1,2,3,4,3,4,5,6,7,8,9,10,1,2,3,4,3,4,5,6,7,8]
    // testArrayForChartLable:Array<any>=["1A","2B","3C","4D","5","1A","2B","3C","4D","5","1A","2B","3C","4D","5","1A","2B","3C","4D","5","1A","2B","3C","4D","5","1A","2B","3C","4D","5"];

  constructor(
    private loaderService: LoaderService,
    private dataProfileService: DataProfileService,
    private toasterService: ToastrService,
    private router: Router,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService) { }
 
  ngOnInit() {

    this.dataElements = [];
    this.tableName=this.activatedRoute.snapshot.paramMap.get("table_Name");
    this.collectionDate=this.activatedRoute.snapshot.paramMap.get("date");
    
    this.dateDqDaily=[];


    this.dateTimeForm = new FormGroup({
      activeEndDate: new FormControl(null, {
        validators: [Validators.required]
      })
    });

    this.selectFilter = new FormGroup({
      selectFilterName: new FormControl(null, {
        validators: [Validators.required]
      })
    });
    
    
    this.getDataProfileSummerytData(this.tableName);
    this.getDataProfileList(this.tableName);
    this.getPieChartData(this.tableName);
    //getDataProfileTableDetails
    this.getDataProfileTableList(this.tableName)
  }
  

  redirectBack() {
    this._location.back();
  }

  dataProfilingDetails(data_element){

    this.router.navigate(["user/dataQuality/dataProfiling/details/","text",{'table_Name':this.tableName,'date':this.collectionDate,"element_name":data_element,"filter_date":this.selectedDpDaily}]);
  }

   //get profile summery values 
   getDataProfileSummerytData(tableName) {
    this.loaderService.display(true);
    this.dataProfileService.getDataProfileCount(tableName).subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.contentResult = data["Message"];
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  getDataProfileList(tableName) {
    this.loaderService.display(true);
    this.dataProfileService.getDataProfileDropDown(tableName).subscribe(
        (data) => {
          
          
          if (data["Success"] > 0) {

            this.responseDateList = data["Message"];

            data["Message"].forEach(element => {
              if (!(this.dataElements.includes(element['date_time_dropdown']))) {
                this.dateDqDaily.push(element['date_time_dropdown']);
              }
            });
            this.dateFilterLenght=this.dateDqDaily.length
            
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

  getDataProfileTableList(tableName) {
    this.loaderService.display(true);
    this.dataProfileService.getDataProfileTableDetails(tableName).subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.responseTableList = data["Message"];

            this.responseTableList.forEach(element1 => {
              if (!(this.dataElements.includes(element1['data_element']))) {
                this.dataElements.push(element1['data_element']);
              }
            })

          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }

//display graph
  ngAfterViewInit() {
    
        this.pieChartProfile1 = new Chart('pieChartProfile1', {
          type: 'bar',
          data: {
            datasets: [{
              label: "Valid",
              data: [],
              backgroundColor: "#6ECBFF",
              borderColor: "#6ECBFF",
              barThickness: 17,
              maxBarThickness: 20,
              minBarLength: 2,

            },{
              label: "Invalid",
              data: [],
              backgroundColor: "#FC5F5F",
              borderColor:"#FC5F5F",
              barThickness: 17,
              maxBarThickness: 20,
              minBarLength: 2,
            }],
            
            labels:[ ],
          },
  
          options: {
            
            layout: {
              padding: {
                top: 10,
                bottom: 25,
                right:10
              }
            },
            maintainAspectRatio: false,
            responsive: true,
            title: {
              display: false,
              text: '',
              position: 'bottom',
              fontColor: '#000000',
              fontFamily: '"HelveticaRegular"',
              fontSize: 14,
              padding: 10,
            },
            // "hover": {
            //   "animationDuration": 0
            // },
            // "animation": {
            //   "duration": 1,
            //     "onComplete": function () {
            //       var chartInstance = this.chart,
            //         ctx = chartInstance.ctx;
                  
            //       ctx.font = Chart.helpers.fontString(14, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            //      // ctx.textAlign = 'center';
            //       ctx.textBaseline = 'bottom';
    
            //       this.data.datasets.forEach(function (dataset, i) {
            //         var meta = chartInstance.controller.getDatasetMeta(i);
            //         meta.data.forEach(function (bar, index) {
            //           var data = dataset.data[index];                            
            //           ctx.fillText(data, bar._model.x, bar._model.y - 5);
            //         });
            //       });
            //     }
            // },
            
            legend: {
              align:'end',
              display: true,
              position: 'top',
             // onClick: (e) => e.stopPropagation(),
              labels: {
                padding: 20,
                fontColor: '#000000',
                fontFamily: '"HelveticaBold"',
                fontSize: 14,
              
              }
            },
            tooltips: {
              enabled: true,
            }

            
          },
        });
  
        this.pieChartProfile2 = new Chart('pieChartProfile2', {
          type: 'pie',
          data: {
            datasets: [{
              data: [],
              backgroundColor: ["#006EFF","#FF00BB","#FF0000","#FFFFFF","#00FFFF","#C0C0C0","#0000FF","#808080","#0000A0","#000000","#ADD8E6","#FFA500","#800080","#A52A2A","#FFFF00","#800000","#00FF00","#008000","#FF00FF","#808000","#7D0541","#566D7E","#9966CC","#66CDAA","#F08080","#9ACD32","#C71585","#B8860B","#483D8B","#ADFF2F",],
              borderColor: ["#006EFF","#FF00BB","#FF0000","#FFFFFF","#00FFFF","#C0C0C0","#0000FF","#808080","#0000A0","#000000","#ADD8E6","#FFA500","#800080","#A52A2A","#FFFF00","#800000","#00FF00","#008000","#FF00FF","#808000","#7D0541","#566D7E","#9966CC","#66CDAA","#F08080","#9ACD32","#C71585","#B8860B","#483D8B","#ADFF2F",],
            }],
            labels:[],
          },
  
          options: {
            layout: {
              padding: {
                top: 25,
                bottom: 15,
              }
            },
            maintainAspectRatio: false,
            title: {
              display: true,
              text: '',
              position: 'top',
              fontColor: '#929eae',
              fontFamily: '"HelveticaRegular"',
              fontSize: 14,
              padding: 10
            },
            legend: {
              display: true,
              position: 'right',
              //onClick: (e) => e.stopPropagation(),
              labels: {
                padding: 9,
                fontColor: '#000000',
                fontFamily: '"HelveticaBold"',
                fontSize: 12,
              }
            },
            "animation": {
        	"duration": 1,
						"onComplete": function () {
							var chartInstance = this.chart,
								ctx = chartInstance.ctx;
							
							ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
							ctx.textAlign = 'center';
							ctx.textBaseline = 'bottom';

							this.data.datasets.forEach(function (dataset, i) {
								var meta = chartInstance.controller.getDatasetMeta(i);
								meta.data.forEach(function (bar, index) {
									var data = dataset.data[index];                            
									ctx.fillText(data, bar._model.x, bar._model.y - 5);
								});
							});
						}
        },
  
            tooltips: {
              enabled: true,
            }
          },
        });
  }

  getPieChartData(tableName) {
    this.loaderService.display(true);
    this.dataProfileService.getDataProfilePieChartData(tableName)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.rseChartList=data['Message'];
            
            // if(this.rseChartList.length>30){
            //   var i:number; 
            //   for(i = 0;i<30;i++) {
            //     this.pieChartProfile1.data.datasets[0].data.push(this.rseChartList[i].valid_count);
            //     this.pieChartProfile1.data.labels.push(this.rseChartList[i].data_element);
            //     this.pieChartProfile1.data.datasets[1].data.push(this.rseChartList[i].invalid_count);
            //     //this.pieChartProfile1.data.labels.push(this.rseChartList[i].data_element);
            //   }
            //   this.pieChartProfile1.update();
            //   //this.pieChartProfile2.update();
            // }else{
            
            //Update Bar char 16/3/2021

            this.udateBarChart(this.rseChartList)
              
              //this.pieChartProfile2.update();
          //  }

          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        });
  }

  
  //onDateFilter code
  onChange(dateValue) {
   this.dateFilterSelect(dateValue)
   this.collectionDate=dateValue;
  }

//date filter select froom top right corner 
  dateFilterSelect(dateValue){
    this.loaderService.display(true);
    this.dataProfileService.getMhubDqDate(this.tableName,dateValue)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {

             this.contentResult=data['dp_count_data'];
             this.udateBarChart(data['dp_pie_chart_data'])
             this.responseTableList=data['dp_table_details_data'];

          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        });
     }

     udateBarChart(barChartList){
      barChartList.forEach((element) => {
        this.pieChartProfile1.data.datasets[0].data.push(element.valid_count);
        this.pieChartProfile1.data.datasets[1].data.push(element.invalid_count);
         this.pieChartProfile1.data.labels.push(element.data_element);
        });
        
        this.pieChartProfile1.update();
    }
}
