import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { log } from 'console';

@Injectable({
  providedIn: "root",
})
export class ReportService {
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  public getToken() {
    let token = null;
    const tokenStr = sessionStorage.getItem("token");
    if (tokenStr) {
      token = tokenStr;
    }
    return token;
  }

  getReportList(index_name,pageNo, pageSize) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "index_data_list";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name: index_name,
        page_no: pageNo,
        page_Size: pageSize
      });
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  //Add new 
  getReportAddData(domain,mData) {
    // add pageSize to API call
    
    let url = this.baseApiUrl + "add_data";

      let postBody = JSON.stringify({
        username: JSON.parse(sessionStorage.getItem("user")).UserName,
        index_name:domain,
        data:mData
      });
      
      return this.http.post(url, postBody).pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

 //add Deatils BT
 getReportAddPageDetails() {
    
  let url = this.baseApiUrl + "add_page_details";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
    });
    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
}

//EDIT Deatils 
getReportEditPageDetails(mId,indexName) {
  
  let url = this.baseApiUrl + "edit_page_details";
  
    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      index_name:indexName,
      _id:mId
    });
    
    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
}

 //Detail Reports
 getReportsDetailsData(mId,index_name) {
  // add pageSize to API call
  
  let url = this.baseApiUrl + "global_search_details";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      index_name:index_name,
      _id:mId
    });
   
    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        
        return response;
        
      })
    );
}


getReportEditData(indexEdit,mId,mData) {
  // add pageSize to API call
  
  let url = this.baseApiUrl + "edit_data";

    let postBody = JSON.stringify({
      username: JSON.parse(sessionStorage.getItem("user")).UserName,
      index_name:indexEdit,
      _id:mId,
      data:mData
    });
    return this.http.post(url, postBody).pipe(
      map((response: Response) => {
        return response;
      })
    );
}


}
