import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared-service.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loaderService';
import { CommonService } from 'src/app/shared/services/commonService.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  searchReference: FormControl;
  subscription: Subscription;
///------------------------------------------------------------

/**
   * Form
   */
  reactiveForm: FormGroup;

  public placeholder: string = 'Global Search';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';

  searchText: FormControl;
  newValue: string;
  searchResult: any;
  searchResultArray: Array<any>=[];
  countriesReactive: Array<any>=[];
  loginCount:any;
   GivenDate:any = '2021-01-31';
   CurrentDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private router: Router,
    private sharedService: SharedService) {
     
  }

  ngOnInit() {

    
    this.userCountAPI();
    this.searchReference = new FormControl('', Validators.required);
    this.reactiveForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
    this.searchServicesAutoSuggest()
    
  }

  searchServices() {
    this.sharedService.setRefSearchText(this.searchReference.value);
    this.router.navigate(['/user', 'searchResults']);
  }
  

  searchServicesAutoSuggest() {
    this.loaderService.display(true);
    this.commonService.getGlobalReferenceSearchResults('all',this.searchReference.value).subscribe(data => {
      this.searchResult = data;
      if(this.searchResult['Success'] > 0){

        this.searchResult['Message'].forEach((value) => {
          
          if(value.index_name === "business_glossaries"){
            this.countriesReactive.push(value.data['term_name']);
          }else if(value.index_name=="reference_master"){
            this.countriesReactive.push(value.data['Reference_Name']);
          }else if(value.index_name=="data_dictionary"){
            this.countriesReactive.push(value.data['table_name']);
          }else{
            this.countriesReactive.push(value.data['Table_Name']);
          }

          // console.log(value.index_name);
          // //BT
          // this.countriesReactive.push(value.data['term_name']);
          // //Ref
          // this.countriesReactive.push(value.data['Reference_Name']);
          // //data_dictionary
          // this.countriesReactive.push(value.data['table_name']);
          // //data_lineage
          // this.countriesReactive.push(value.data['Table_Name']);

        })
      }
      else{
        this.toasterService.error(this.searchResult['Message']);
      }
      this.loaderService.display(false);
    },
      error => {
        this.loaderService.display(false);
        this.toasterService.error(error);
      })
  }

  searchServices1(){
    //console.log("added vale");
    
  }

  

  userCountAPI() {
    this.loaderService.display(true);
    this.commonService.getUserLoginCount().subscribe(data => {
      
      if(data["Success"] > 0){
        this.loginCount=data["Message"]
      }
      else{
        this.toasterService.error(data["Message"]);
      }
      this.loaderService.display(false);
    },
      error => {
        this.loaderService.display(false);
        this.toasterService.error(error);
      })
  }

}
