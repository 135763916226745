import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'dataquality'
})
export class DataQualityPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    
    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    
    return items.filter(item => {
      if(item.table_name != null){
        return item.table_name.toLowerCase().includes(searchText);
      }
    });
  }
}