import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LoaderService } from 'src/app/services/loaderService';
import { DataLineageService } from 'src/app/shared/services/dataLineage.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-data-lineage-details',
  templateUrl: './data-lineage-details.component.html',
  styleUrls: ['./data-lineage-details.component.scss']
})
export class DataLineageDetailsComponent implements OnInit {

  dataDictionaryId: string;
  dataLineageDeatilMessage: Array<any> = [];
  lineageUrl:any

  constructor(
    private _location: Location,
    private loaderService: LoaderService,
    private dataLineageService: DataLineageService,
    private activatedRoute: ActivatedRoute,
    private dom:DomSanitizer,
    private toasterService: ToastrService) { 

    }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.dataDictionaryId = params["id"];
      //debugger;
      this.getDataLineagePageDetails(this.dataDictionaryId)
    });
  }

 

  redirectBack() {
    this._location.back();
  }


   //Api call
   getDataLineagePageDetails(mId) {
    //debugger;
    this.loaderService.display(true);
    this.dataLineageService
      .getDataLineageDetailsData(mId)
      .subscribe(
        (data) => {
          if (data["Success"] > 0) {
            this.dataLineageDeatilMessage = data["Message"];
            
            this.lineageUrl = this.dom.bypassSecurityTrustHtml("<iframe  src='" + this.dataLineageDeatilMessage['URL'] + "' style='width:inherit;height: 100vh;border: 0px;'></iframe>");
          } else {
            this.toasterService.error(data["Message"]);
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.toasterService.error("Something went wrong!");
        }
      );
  }


}
