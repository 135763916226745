import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'dqDate'
})
export class DqDatePipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (!items) return [];

    if (!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      if (item.date_time != null) {
        return item.date_time.toLowerCase().includes(searchText);
      }
    });
  }
}